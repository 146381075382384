import { FC, useState, useEffect } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { User } from 'types/User';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Pool } from 'types/Pool';

//utils
import { movePointsBetweenPools } from '../../PointsPage/services/PointsPage.services';

//style
import { 
    SendPointsBox,
    AmountInput,
    DescriptionInput,
    Label,
    TransferButton,
    Row,
} from './style/TakePoints.style';

const TakePoints: FC<{ user: User, updateLocalUser: () => void }> = ({ user, updateLocalUser }) => {
    const [amount, setAmount] = useState<number>(0);
    const [points, setPoints] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [fromPoolId, setFromPoolId] = useState<number | undefined>();
    const [toPoolId, setToPoolId] = useState<number>(14906);

    const dispatch = useAppDispatch();

    const onTransferClickHandler = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Vuoi veramente prelevare ' + amount + ' punti dalla pool personale ' + fromPoolId + ' di ' + user.name + ' ' + user.surname + ' ?')) {
		const snackbarData: SnackbarOptions = {};
		if (fromPoolId && toPoolId && amount && description)
			try {
				await movePointsBetweenPools(fromPoolId, toPoolId, amount, description);
				snackbarData.type = 'success';
				snackbarData.message = 'Trasferimento punti completato con successo';
				dispatch(addSnackbar(snackbarData));
				setAmount(0);
				setPoints('');
				setDescription('');
				updateLocalUser();
			} catch (error) {
				snackbarData.type = 'error';
				snackbarData.message = "Errore nel trasferimento dei punti";
				dispatch(addSnackbar(snackbarData));
			}
        }
	};

    useEffect(() => {
        try {
            if (isNaN(Number(points.replaceAll(',', '.')))) {
                setAmount(0);
                return;
            }
            setAmount(Number(points.replaceAll(',', '.')));
        } catch (error) {
            setAmount(0);
        }
    }, [points]);

    useEffect(() => {
        const personalPools: Pool[] = user.pools.filter((pool) => pool.type === 'PERSONAL');
        const sortedPersonalPools = personalPools.sort((a, b) => a.id - b.id);
        if (!personalPools || !sortedPersonalPools) return;
        setFromPoolId(sortedPersonalPools[0]?.id);
    }, [user]);

    return (<>
        <SendPointsBox>
            <Row>
                <Label>
                    Punti:
                </Label>
                <AmountInput 
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                />
            </Row>
            <Row>
                <Label>
                    Causale:
                </Label>
                <DescriptionInput 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Row>
            <Row align="right">
                <TransferButton disabled={amount! === 0 || description === '' || !toPoolId} onClick={onTransferClickHandler}>
                    Preleva punti
                </TransferButton>
            </Row>
        </SendPointsBox>
    </>);
};

export default TakePoints;
