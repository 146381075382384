import colors from 'style/palette/palette';
import styled from 'styled-components';


export const ButtonBlock = styled.div<{isUserAdmin: boolean; disabled?: boolean}>`

	cursor: ${({isUserAdmin, disabled}) => disabled ? 'default' : isUserAdmin ? 'pointer' : 'default'};
	position: relative;
`;

export const EditBox = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100% !important;
	height: 100%;
	min-height: 25px;

	padding: 10px;

	border-radius: 5px;
	border: 2px solid ${colors.white070};
	background: ${colors.black087};

	font-size: 25px;
	color: ${colors.white070};

	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;

`;

export const ButtonToDisplay = styled.button`

    margin: 0;
    padding: 5px 15px;
    
	font-size: 15px;
	font-weight: 500;
	
    border: unset;
    border-radius: 5px;
    box-shadow: unset;
    background: ${colors.primaryColor};
	cursor: pointer;
`