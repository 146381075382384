import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableRowProps } from './EditableRow.component.d';

//style
import { 
	RowBlock,
	EditBox
 } from './style/EditableRow.component.style';
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';

const EditableRow: FC<EditableRowProps> = ({
	webpageId,
	rowId,
	newElementPos,
	children,
	update,
	refreshProduct,
	disabled
}) => {
	const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);

    const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
    const userHasAdminRole = isAdmin || isSuperAdmin;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyImageClickHandler = () => {
        
        if (!userHasAdminRole) return;
		setIsEditorEnabled(prevState => !prevState);
	};
	
	return (
		<>
			<RowBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyImageClickHandler}
				isUserAdmin={userHasAdminRole}
				disabled={disabled}
			>
				{children}

				{isOnHover && userHasAdminRole && !disabled && (
					<EditBox>
						Modifica riga
					</EditBox>
				)}
			</RowBlock>

			{isEditorEnabled && userHasAdminRole && !disabled && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					update={update}
					elementId={rowId}
					elementsToDisplay={children}
					typeOfLayout='ROW'
					newElementPos={newElementPos}
					blockId={rowId}
					updateData={refreshProduct}
					webpageId={webpageId}
				/>
			)}
		</>
	);
};

export default EditableRow;
