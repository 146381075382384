import { FC, useState, useEffect, useRef } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import { useParams, useSearchParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useBrand from 'hooks/useBrand';

//types
import { RoadmapEventTypeDetailPageProps } from './RoadmapEventTypeDetailPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { RoadmapEventType } from 'types/RoadmapEventType';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//assets
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import EditableInput from 'components/EditableComponents/EditableInput';
import CheckboxInput from 'components/ui/CheckboxInput';

//utils
import { getRoadmapEventTypeFromId, putRoadmapEventType, uploadFile, deleteFile } from 'services/roadmapeventtype.services';
import isEqual from 'lodash/isEqual';

//style
import { Row, Col } from 'style/layout';
import { Page, BaseContentCard, InfoLabel, ProductImage, ProductImageContainer, ProductImageNewFile, ImageEditIconOverlay, PicButton } from './style/RoadmapEventTypeDetailPage.component.style';

const RoadmapEventTypeDetailPage: FC<RoadmapEventTypeDetailPageProps> = () => {
	const [roadmapeventtype, setRoadmapEventType] = useState<RoadmapEventType>({} as RoadmapEventType);
	const [subPath, setSubPath] = useState<string | undefined>('');
	const [localLiveDate, setLocalLiveDate] = useState<Date>();
	const [localLiveHour, setLocalLiveHour] = useState(-1);
	const [localLiveMinute, setLocalLiveMinute] = useState(-1);
	const [localAbsoluteLiveDate, setLocalAbsoluteLiveDate] = useState('');
	const [imageIsClickable, setImageIsClickable] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasChanged, setHasChanged] = useState<boolean>(false);

	const brand = useBrand();

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isRoadmap = useHasRole('ROADMAP-'+brand?.code);

	const productImageNewFileInput = useRef<HTMLInputElement>(null);

	const roadmapeventtypesList: RoadmapEventType[] = [];


	const { eventTypeId } = useParams();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const numericId: number = eventTypeId ? +eventTypeId : 0;

	const getRoadmapEventType = async (numericId: number) => {
		if (!brand) return;
		if (numericId === 0) return;
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getRoadmapEventTypeFromId(numericId, brand.code);
			setRoadmapEventType(response);
			setIsLoading(false);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli eventtypei';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const saveRoadmapEventType = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		if (roadmapeventtype.name.trim() === '') {
			snackbarData.type = 'error';
			snackbarData.message = "Devi specificare un nome per poter salvare.";
			dispatch(addSnackbar(snackbarData));
			return;
		}
		if (!roadmapeventtype.defaultImage) {
			snackbarData.type = 'error';
			snackbarData.message = "Devi specificare l'immagine predefinita per poter salvare.";
			dispatch(addSnackbar(snackbarData));
			return;
		}

		try {
			var localEventType: RoadmapEventType = roadmapeventtype;
			
			await putRoadmapEventType(localEventType, brand.code);
			setHasChanged(false);
			snackbarData.type = 'success';
			snackbarData.message = "EventTypeo aggiornato con successo";
			dispatch(addSnackbar(snackbarData));
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio dell'eventtypeo";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const saveLocalRoadmapEventType = (inputId: string, value: string) => {
		var localEventType: RoadmapEventType = { ...roadmapeventtype };
		if (inputId === 'name') localEventType.name = value;
		if (!isEqual(localEventType, roadmapeventtype)) setHasChanged(true);
		setRoadmapEventType(localEventType);
	}

	const uploadB64 = async (productId: number, fileB64: string, fileName: string): Promise<any | null> => {
		if (!brand) return;
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				formData.append('file', blob, fileName);
				uploaded = await uploadFile(productId, formData, brand.code);
			});
		return uploaded;
	};

	const getFileContentInBase64 = (files: File[]) => {
		const fileReadPromises = files.map((file) => {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result as string);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				reader.readAsDataURL(file);
			});
		});
		return Promise.all(fileReadPromises);
	};

	const handleSingleFileChange = async (eventtype: React.ChangeEvent<HTMLInputElement>) => {
		const files = eventtype.target.files;
		if (files && files.length > 0) {
			const fileB64: string[] = await getFileContentInBase64([files[0]]);
			var uploaded = await uploadB64(roadmapeventtype.id, fileB64[0], files[0].name);
			if (uploaded) {
				var partial: Partial<RoadmapEventType> = { id: roadmapeventtype.id, defaultImage: uploaded };
				var localEventType: RoadmapEventType = { ...roadmapeventtype };
				localEventType.defaultImage = uploaded;
				setRoadmapEventType(localEventType);
			}
		}
	};

	const onImageOver = () => {
		setImageIsClickable(true);
	};

	const onImageOut = () => {
		setImageIsClickable(false);
	};

	const onImageClick = (bypass?: Boolean) => {
		if (imageIsClickable || bypass) {
			if (productImageNewFileInput.current) {
				productImageNewFileInput.current.click();
			}
		}
	};

	const onRemoveImageClick = async () => {
		if (!brand) return;
		if (window.confirm(`Rimuovere l'immagine dell'eventtypeo?`)) {
			try {
				await deleteFile(roadmapeventtype.id, brand.code);
				var localEventType: RoadmapEventType = { ...roadmapeventtype};
				localEventType.defaultImage = null;
				setRoadmapEventType(localEventType);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "Errore durante la rimozione dell'immagine";
				dispatch(addSnackbar(snackbarData));
			}
		}
	}

	useEffect(() => {
		if (!brand) return;
		getRoadmapEventType(numericId);
	}, [numericId, brand]);

	useEffect(() => {
		if (!isLoading) setSubPath((subPath) => 'Tipo / ' + eventTypeId?.toString() + ' | ' + roadmapeventtype?.name);
	}, [isLoading, roadmapeventtype, eventTypeId]);

	const breadCrumbSettings: TitleBreadCrumbSettings = {
		subSectionText: 'Gestione Roadmap',
		subSubSectionText: subPath,
		subSectionLink: '/reservedArea/roadmap',
	};

	if (!isSuperAdmin && !isRoadmap) return (<></>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

			{isLoading && <>Loader</>}
			{!isLoading && (
			<BaseContentCard>
				<Row>
					<Col width="50%" margin="20px">
						<Row>
							<InfoLabel>Nome:</InfoLabel>

							<EditableInput
								inputId={'name'}
								editableInput={roadmapeventtype.name}
								update={saveLocalRoadmapEventType}
								placeholder='Inserisci il nome'
							/>
						</Row>

						<Row>
							<InfoLabel>Immagine predefinita:</InfoLabel>

							<ProductImageContainer>
								<ProductImage
									src={roadmapeventtype.defaultImage?.url ? roadmapeventtype.defaultImage.url : ""}
									onMouseOver={onImageOver}
									onMouseOut={onImageOut}
									onClick={() => onImageClick(false)}
								/>

								<ImageEditIconOverlay style={{ opacity: imageIsClickable ? 1 : 0 }}>
									<EditIconSVG />
								</ImageEditIconOverlay>
							</ProductImageContainer>
							{!roadmapeventtype.defaultImage?.url &&
								<PicButton onClick={() => onImageClick(true)}>Carica</PicButton>
							}
							{roadmapeventtype.defaultImage?.url &&
								<PicButton onClick={onRemoveImageClick}>Rimuovi</PicButton>
							}

							<ProductImageNewFile
								ref={productImageNewFileInput}
								onChange={handleSingleFileChange}
							/>
						</Row>
					</Col>
				</Row>
				<Row>
					<button onClick={saveRoadmapEventType} disabled={!hasChanged}>SAVE</button>
				</Row>
			</BaseContentCard>
			)}
		</Page>
	);
};

export default RoadmapEventTypeDetailPage;
