import styled from 'styled-components';
import colors from 'style/palette';


export const PointsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 122px;
    padding: 10px 0 2px;

    color: ${colors.white};
    font-size: 0.8em;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    min-width: 160px;
`;

export const PoolName = styled.div`
    font-weight: bold;
`;

export const PoolBalance = styled.div`

`;
