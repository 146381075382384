import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableButtonProps } from './EditableButton.component.d';

// components
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';

//style
import { 
	ButtonBlock,
	ButtonToDisplay,
	EditBox
 } from './style/EditableButton.style';


const EditableButton: FC<EditableButtonProps> = ({
	productId,
	blockId,
	buttonElementId,
	buttonText,
	isRequired,
	update,
	refreshProduct,
	disabled
}) => {

	const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);

	const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
    const userHasAdminRole = isAdmin || isSuperAdmin;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyButtonClickHandler = () => {

        if (!userHasAdminRole) return;
		setIsEditorEnabled(prevState => !prevState);
	};
	
	return (
		<>
			<ButtonBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyButtonClickHandler}
				isUserAdmin={userHasAdminRole}
				disabled={disabled}
			>
				<ButtonToDisplay>
                    {buttonText}
                </ButtonToDisplay>

				{isOnHover && userHasAdminRole && !disabled && (
					<EditBox>
						Modifica il testo del bottone
					</EditBox>
				)}
			</ButtonBlock>

			{isEditorEnabled && userHasAdminRole && !disabled && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					update={update}
					blockId={blockId}
					elementId={buttonElementId}
					typeOfElement='BUTTON'
					isRequired={isRequired}
					productId={productId}
					updateData={refreshProduct}
				/>
			)}
		</>
	);
};

export default EditableButton;
