import { FC, useEffect, useState } from 'react';

//types
import { SubCategoryProps } from './SubCategory.component.d';

//style
import { SubCategoryBox } from './style/SubCategory.component.style';
import { Product } from 'types/Product';
import useAppSelector from 'hooks/useAppSelector';
import { Category } from 'types/Category';

const SubCategory: FC<SubCategoryProps> = ({ subCategory, product, putProduct, isReadOnly }) => {
	const [enabled, setEnabled] = useState(false);
	const [parentCategory, setParentCategory] = useState<Category>();

	useEffect(() => {
		setEnabled(product.subCategories?.some((ps) => ps.name === subCategory.name));
	}, [product, subCategory]);

	const categories = useAppSelector((state) => state.global.allProductCategories);

	useEffect(() => {
		setParentCategory(categories?.find((c) => c.id === subCategory.categoryId));
	}, [categories, subCategory]);

	const clickHandler = async () => {
		const updatedProduct: Partial<Product> = { id: product.id };
		if (!enabled) {
			try {
				if (product.subCategories) updatedProduct.subCategories = [...product.subCategories, subCategory];
				if (!product.subCategories) updatedProduct.subCategories = [subCategory];
				if (!product.categories.some((c) => c.id === subCategory.categoryId) && parentCategory) {
					updatedProduct.categories = [...product.categories, parentCategory];
				}
				await putProduct(updatedProduct);
				setEnabled(true);
			} catch (error) {
				console.log(error);
			}
		}
		if (enabled) {
			try {
				updatedProduct.subCategories = product.subCategories.filter((s) => s.id !== subCategory.id);
				await putProduct(updatedProduct);
				setEnabled(false);
			} catch (error) {
				console.log(error);
			}
		}
	};
	return (
		<SubCategoryBox
			onClick={clickHandler}
			enabled={enabled}
			disabled={isReadOnly}
		>
			{subCategory?.name}
		</SubCategoryBox>
	);
};

export default SubCategory;
