import styled from 'styled-components';
import colors from 'style/palette/palette';


export const Fieldset = styled.fieldset`
    width: 100%;
    height: 100%;

    // for the future:
    //   children padding left is final minus 16px!
    padding: 0 0 0 16px;
    padding-top: revert;

    border-radius: 4px;
    border:
        1px solid ${colors.white023};
`;

export const Legend = styled.legend`

    padding: 0 4px;

    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: ${colors.white070};
`;
