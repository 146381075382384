import { FC, useEffect, useState } from 'react';

// utils
import { callDELETE, callGET, callPOST, callPUT } from 'network/network';

//types
import { Product } from 'types/Product';
import { SalesPageTabProps } from './SalesPageTab.component.d';

//components
import PublicProductPages from 'pages/PublicProductPages/PublicProductPage.component';

//style
import { MainBox } from './style/SalesPageTab.component.style';

const SalesPagTab: FC<SalesPageTabProps> = ({ product, updateProductData, videoOnly, isReadOnly }) => {

	const [productInfo, setProductInfo] = useState<any>(product);

	// const getWebPage = async (webPageId: number) => {

	// 	try {
	// 		const response = await callGET(`v1/webpages/${webPageId}`, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const getWebPageAllBlocks = async (webPageId: number) => {

	// 	try {
	// 		const response = await callGET(`v1/webpages/${webPageId}/blocks`, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const getWebPageBlock = async (blockId: number) => {

	// 	try {
	// 		const response = await callGET(`v1/webpages/blocks/${blockId}/elements`, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const getWebPageBlockElement = async (elementId: number) => {

	// 	try {
	// 		const response = await callGET(`v1/webpages/blocks/elements/${elementId}`, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const newWebPageBlock = async (webPageId: number, newBlock: Partial<Product>) => {

	// 	const blockPayload = newBlock;
	// 	try {
	// 		const response = await callPOST(`v1/webpages/blocks/${webPageId}/elements`, {}, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const newWebPageBlockElement = async (elementId: number, newElement: Partial<Product>) => {

	// 	const elementPayload = newElement;
	// 	try {
	// 		const response = await callPOST(`v1/webpages/blocks/elements/${elementId}`, elementPayload, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const modifyWebPageBlock = async (partialBlock: Partial<Product>) => {

	// 	const blockPayload = partialBlock;
	// 	try {
	// 		const response = await callPUT(`v1/webpages/blocks`, blockPayload, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const modifyWebPageBlockElement = async (partialElement: Partial<Product>) => {

	// 	const elementPayload = partialElement;
	// 	try {
	// 		const response = await callPUT(`v1/webpages/blocks/elements`, partialElement, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const deleteWebPageBlock = async (blockId: number) => {

	// 	try {
	// 		const response = await callDELETE(`v1/webpages/blocks/${blockId}`, {}, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	// const deleteWebPageBlockElement = async (elementId: number) => {

	// 	try {
	// 		const response = await callDELETE(`v1/webpages/blocks/elements/${elementId}`, {}, true);

	// 		console.log("RESPONSE:", response);
	// 	} catch (error: any) {
	// 		console.warn("ERROR:", error);
	// 	}
	// };

	useEffect(() => {
		setProductInfo(product)
	}, [product]);
	
	return (
		<MainBox>
			<PublicProductPages 
				product={productInfo}
				// putProduct={putProduct}
				updateProductData={updateProductData!}
				videoOnly={videoOnly}
				isReadOnly={isReadOnly}
			/>
		</MainBox>
	);
};

export default SalesPagTab;
