import { FC, useEffect, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { RequirementsTabProps } from './RequirementsTab.component.d';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import ScrollableTextList from 'components/ScrollableTextList';
import RequirementsAndOr from '../RequisitesAndOr';

//style
import { 
    MainBox, 
    RowBox, 
    ColumnBox,
    InputBox,
    CheckboxInput,
    Label,
    SimpleSelect,
    ButtonsRowBox,
    CreateButton,
    ResetButton,
    CancelButton,
} from './style/RequirementsTab.component.style';
import { Product } from 'types/Product';
import { getValue } from '@testing-library/user-event/dist/utils';

const RequirementsTab: FC<RequirementsTabProps> = ({ product, putProduct, updateProductData, isReadOnly }) => {

    const [selectedRequirementProductId, setSelectedRequirementProductId] = useState(null);
    const [requirementsList, setRequirementsList] = useState<any[]>([]);
    const [productsList, setProductsList] = useState<any>([]);
    const [selectedRequirementId, setSelectedRequirementId] = useState<number | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [negation, setNegation] = useState<boolean>(false);
    
	const products = useAppSelector((state) => state.global.allProducts);

	const dispatch = useAppDispatch();

    const onChangeProductSelectHandler = (event: any) => {
        setSelectedRequirementProductId(event.target.value);
        console.log("Set ID: ", event.target.value);
    };

    const handleNegationCheckBox = () => {
        setNegation((prevState) => !prevState);
    }

    const handleCreateRequirement = () => {
        setIsCreating(true);
        setSelectedRequirementProductId(null);
    }

    const handleClose = () => {
        setIsCreating(false);
        setSelectedRequirementProductId(null);
    }

    const handleRemoveRequirement = async () => {
        if (!selectedRequirementId) return;
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Vuoi veramente rimuovere il requisito selezionato???')) {
            try {
                const updatedRequisites = product.requisites.filter(item => item.id.requisiteProductId !== selectedRequirementId);

                if (!updatedRequisites) return;
                let updatedProduct: Product = { id: product.id, requisites: updatedRequisites } as Product;
				await putProduct(updatedProduct);
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'success';
                snackbarData.message = "Requisito rimosso con successo!";
                dispatch(addSnackbar(snackbarData));
                // loadRequirement(restoreRequirement);
            } catch (error: any) {
                const snackbarData: SnackbarOptions = {};
                snackbarData.type = 'error';
                snackbarData.message = "C'è stato un problema nel rimuovere il requisito!";
                dispatch(addSnackbar(snackbarData));
                throw error;
            }
        }
    }

	const handleSaveRequirement = async () => {
        if (selectedRequirementProductId && selectedRequirementProductId>0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Vuoi veramente aggiungere il nuovo Requisito???')) {
                try {
                    let updatedRequisites = product.requisites as any;
                    updatedRequisites = updatedRequisites.map((item: any) => {
                        const { requisiteProduct, ...rest } = item;
                        return rest;
                    });
                    let newRequisite = {
                        id: {
                            productId: product.id,
                            requisiteProductId: Number(selectedRequirementProductId)
                        },
                        negation: negation,
                    };
                    updatedRequisites = [...updatedRequisites, newRequisite];
                    let updatedProduct: Product = { id: product.id, requisites: updatedRequisites } as Product;
                    await putProduct(updatedProduct);
                    const snackbarData: SnackbarOptions = {};
                    snackbarData.type = 'success';
                    snackbarData.message = "Requisito aggiunto con successo!";
                    dispatch(addSnackbar(snackbarData));
                    handleClose();
                    // loadRequirement(restoreRequirement);
                } catch (error: any) {
                    const snackbarData: SnackbarOptions = {};
                    snackbarData.type = 'error';
                    snackbarData.message = "C'è stato un problema nell'aggiungere il requisito!";
                    dispatch(addSnackbar(snackbarData));
                    throw error;
                }
            }
        }
	};

    useEffect(() => {
        if (products && product.requisites) {
            var requirementsStrings: any[] = [];
            console.log(product.requisites);
            product.requisites.forEach((requirement: any, index) => {
                var reqproduct = products.find(product => product.id === requirement.id.requisiteProductId);
                if (reqproduct) {
                    var requirementString = (requirement.negation) ? "NON avere" : "AVERE";
                    requirementString += ": "+reqproduct.name+"("+reqproduct.type+") | "+reqproduct.id;
                    requirementsStrings.push({
                        id: reqproduct.id,
                        label: requirementString,
                    });
                }
            });
            setRequirementsList(requirementsStrings.sort((a, b) => b.id - a.id));
            const requisiteProductIds: number[] = [];

            product.requisites.forEach(requisite => {
                if (requisite.id && typeof requisite.id.requisiteProductId === 'number') {
                    requisiteProductIds.push(requisite.id.requisiteProductId);
                }
            });

            const filteredProducts = products.filter(product => !requisiteProductIds.includes(product.id));

            const sortedProducts = filteredProducts.slice().sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
              
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });

            setProductsList(sortedProducts);
        }
    }, [products, product]);

    return (
    <MainBox>
        <RowBox>
            <BaseFieldset legend='REQUISITI ATTUALI'>
                <RequirementsAndOr
                    product={product}
                    putProduct={putProduct}
                    disabled={false}
                />
                {(requirementsList.length > 0 || product.requisites.length === 0) ?
                <ScrollableTextList 
                    items={requirementsList} 
                    selectedItemId={null} 
                    onItemSelected={(number: number) => setSelectedRequirementId(number) } />
                : "Caricamento..." }
                <br/>
            </BaseFieldset>
        </RowBox>
        <RowBox>
            <ButtonsRowBox>
                    <ResetButton
                        // disabled={(!selectedRequirementId) || !!((activeRequirement) && (activeRequirement.id) && (selectedRequirementId) && (selectedRequirementId === activeRequirement.id))}
                        onClick={handleRemoveRequirement}
                    >
                        Rimuovi il Requisito Selezionato
                    </ResetButton>
                    <CreateButton
                        onClick={handleCreateRequirement}
                    >
                        Crea Nuovo Requisito
                    </CreateButton>
                </ButtonsRowBox>
        </RowBox>
        <RowBox>
            { isCreating &&
            <BaseFieldset legend='CREA REQUISITO'>
                <RowBox>
                    <ColumnBox>
                    { products && 
                        <InputBox>
                            <Label>Prodotto:</Label>
                            <SimpleSelect onChange={onChangeProductSelectHandler}>
                                <option value={-1} selected>SCEGLI UN PRODOTTO</option>
                                {
                                    productsList.map((product: any) => {
                                        return (<option value={product.id}>{product.name} ({product.type}) | {product.id}</option>);
                                    })
                                }
                            </SimpleSelect>
                        </InputBox>
                    }
                    </ColumnBox>
                    <ColumnBox>
                        <InputBox>
                            <Label>Negazione:</Label>
                            <CheckboxInput
                                name='opzNegation'
                                id='opzNEgation'
                                value=''
                                checked={negation}
                                onChange={handleNegationCheckBox}
                            />
                        </InputBox>
                    </ColumnBox>
                </RowBox>
                <ButtonsRowBox>
                    <CancelButton
                        onClick={handleClose}
                    >
                        Annulla
                    </CancelButton>
                    <CreateButton
                        disabled={!selectedRequirementProductId || selectedRequirementProductId <= 0}
                        onClick={handleSaveRequirement}
                    >
                        Salva e aggiungi il Nuovo Requisito
                    </CreateButton>
                </ButtonsRowBox>
            </BaseFieldset> 
            }          
        </RowBox>
    </MainBox>
    );
}

export default RequirementsTab;