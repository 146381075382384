import styled from 'styled-components';
import colors from 'style/palette';

export const SubscriptionLimitBox = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-direction: column;
`;

export const SubscriptionLabel = styled.div`
	padding: 0.5rem;
	font-weight: bold;
	width: 25%;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const SubscriptionMaxNumber = styled.input`
	width: 80px;
`;

export const SubscriptionCurrentNumber = styled.span`
	font-size: 0.8em;
`;

export const SubscriptionMaxDate = styled.input``;

export const HoursDiv = styled.div`
	margin: 0px;
	padding: 2px;
	text-align: center;
	font-size: 0.8em;
	color: white;
`;

export const HoursSelect = styled.select`
	margin: 0px;
	padding: 0px;
`;

export const SaveButton = styled.button`
	width: 25%;
	align-self: center;
	margin: 2rem 0;
`;

export const RemoveButton = styled.button`
    font-size: 1.1em;

    background-color: red;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;

export const UnlockButton = styled.button`
    font-size: 1.1em;

    background-color: orange;
    color: white;

    border: none;
    border-radius: 4px;

    cursor: pointer;
`;