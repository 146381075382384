import styled from 'styled-components';
import colors from 'style/palette';

export const SendPointsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0 10px;
    min-height: 120px;
    height: 100%;
    gap: 5px;
    color: ${colors.white};
    font-size: 0.8em;
`;

export const AmountInput = styled.input`
    width: 7rem;
    margin: 0 0 0 15px;
`;

export const DescriptionInput = styled.input`
    width: 12rem;
`;

export const Label = styled.label`
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 10px;
`;

export const TransferButton = styled.button<{ disabled: boolean }>`
    margin: 0 0;
    width: 150px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const Row = styled.div<{ align?: 'left' | 'right' }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 10px;
    gap: 10px;
    justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
`;