import { FC, useState, useEffect } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//types
import { User } from 'types/User';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Pool } from 'types/Pool';

//utils
import { sendVipPoints } from '../../PointsPage/services/PointsPage.services';

//style
import { 
    SendPointsBox,
    AmountInput,
    DescriptionInput,
    Label,
    TransferButton,
    Row,
} from './style/SendVipPoints.style';

const SendPoints: FC<{ user: User, updateLocalUser: () => void }> = ({ user, updateLocalUser }) => {
    const [amount, setAmount] = useState<number>(0);
    const [points, setPoints] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const dispatch = useAppDispatch();

    const onTransferClickHandler = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Vuoi veramente assegnare ' + amount + ' punti vip a ' + user.name + ' ' + user.surname + ' ?')) {
		const snackbarData: SnackbarOptions = {};
		if (user.id && amount && description)
			try {
				await sendVipPoints(user.id, amount, description);
				snackbarData.type = 'success';
				snackbarData.message = 'Assengnazione punti vip completato con successo';
				dispatch(addSnackbar(snackbarData));
				setAmount(0);
				setPoints('');
				setDescription('');
				updateLocalUser();
			} catch (error) {
				snackbarData.type = 'error';
				snackbarData.message = "Errore nell'assegnazione dei punti vip";
				dispatch(addSnackbar(snackbarData));
			}
        }
	};

    useEffect(() => {
        try {
            if (isNaN(Number(points.replaceAll(',', '.')))) {
                setAmount(0);
                return;
            }
            setAmount(Number(points.replaceAll(',', '.')));
        } catch (error) {
            setAmount(0);
        }
    }, [points]);

    return (<>
        <SendPointsBox>
            <Row>
                <Label>
                    Punti Vip:
                </Label>
                <AmountInput 
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                />
            </Row>
            <Row>
                <Label>
                    Causale:
                </Label>
                <DescriptionInput 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Row>
            <Row align="right">
                <TransferButton disabled={amount! === 0 || description === ''} onClick={onTransferClickHandler}>
                    Assegna punti vip
                </TransferButton>
            </Row>
        </SendPointsBox>
    </>);
};

export default SendPoints;
