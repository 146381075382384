import { FC, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableImageProps } from './EditableImage.component.d';

// components
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';

//style
import { 
	ImageBlock,
	ImageToDisplay,
	EditBox
 } from './style/EditableImage.style';


const EditableImage: FC<EditableImageProps> = ({
	blockId,
	webpageId,
	imageElementId,
	imageUrl,
	typeOfLayout,
	numberOfElements,
	update,
	refreshProduct,
	disabled
}) => {

	const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);

    const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
    const userHasAdminRole = isAdmin || isSuperAdmin;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyImageClickHandler = () => {
		        
        if (!userHasAdminRole) return;
		setIsEditorEnabled(prevState => !prevState);
	};
	
	return (
		<>
			<ImageBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyImageClickHandler}
                isUserAdmin={userHasAdminRole}
                disabled={disabled}
			>
				<ImageToDisplay 
					src={imageUrl}
					alt='Displayed image'
				/>

				{isOnHover && userHasAdminRole && !disabled && (
					<EditBox>
						Modifica immagine
					</EditBox>
				)}
			</ImageBlock>

			{isEditorEnabled && userHasAdminRole && !disabled && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					update={update}
					updateData={refreshProduct}
					blockId={blockId}
					webpageId={webpageId}
					elementId={imageElementId}
					typeOfElement='IMAGE'
					typeOfLayout={typeOfLayout}
					numberOfElements={numberOfElements}
				/>
			)}
		</>
	);
};

export default EditableImage;
