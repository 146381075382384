import { FC, useEffect, useRef, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { saveUserLoginInfo } from 'redux/actions/actions-user';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';

//utils
import { getChannelsList, getMessagesList, postMessage, deleteMessage, uploadFile } from './services/SendNotificationsPage.services';

//types
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as ClearButtonIconSVG } from './assets/clearButtonIcon.svg';
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';
import channelNoIconSVG from './assets/channelNoIcon.svg';

//tools
import { CKEditor, CKEditorEventPayload } from 'ckeditor4-react';
import he from 'he';

//style
import { BaseContentCard } from 'style/wrappers/wrappers';

import {
	Page,
	SearchChannelBox,
	SearchInput,
	SearchClearButton,
	SearchButton,
	ChannelsBox,
	ChannelIcon,
	NotificationEditorBox,
	OddsscasserLinkBox,
	PremiumBox,
	UploadMultipleFilesBox,
	UploadMultipleFilesTitle,
	UploadMultipleFilesBoxTitle,
	UploadMultipleFilesButtonBox,
	UploadMultipleFilesSelector,
	UploadMultipleFiles,
	UploadMultipleFilesList,
	UploadMultipleFilesItem,
	UploadMultipleFilesRemoveButton,
	PasteImagesBox,
	MiniEditBox,
	MiniPreviewBox,
	MiniPreview,
	ClearMiniEditButton,
	MainTextEditorBox,
	MainTextEditorTitle,
	NotificationSendBox,
	NotificationSendButton,
	NotificationStopEditingButton,
	NotificationSendSingleFile,
	NotificationSingleFile,
	NotificationHistoryBox,
	NotificationHistoryTitle,
	NotificationHistoryMessage,
	NotificationHistoryMessagePremiumFlag,
	NotificationHistoryMessageContent,
	NotificationHistoryMessageImage,
	NotificationHistoryMessageDate,
	NotificationHistoryMessageEditButton,
	NotificationHistoryMessageDeleteButton,
	PreviewBox,
	PreviewTitle,
	InputPreview,
} from './style/SendNotificationsPage.style';
import { InAppMessageInteractionEvent } from '@aws-amplify/notifications';
import { getEnvironmentBaseUrl } from 'configs/config.helper';

let alreadySentMessagesModel: any[] = [
	{
		id: 0,
		sendingDate: new Date(),
		content: 'Contenuto del messaggio',
		premiumContent: 'Contenuto premium',
	},
	{
		id: 1,
		sendingDate: new Date(),
		content: 'Contenuto del messaggio 2',
		premiumContent: 'Contenuto premium 2',
	},
];

const MAX_COUNT = 6;

const SendNotificationsPage: FC<{}> = () => {
	const [isSending, setIsSending] = useState<boolean>(false);
	const [editingId, setEditingId] = useState<number | null>(null);
	const [searchInput, setSearchInput] = useState<any>('');
	const [channelsList, setChannelsList] = useState<any[]>([]);
	const [filteredChannelsList, setFilteredChannelsList] = useState<any[]>([]);
	const [selectedChannel, setSelectedChannel] = useState<any>();
	const [alreadySentMessages, setAlreadySentMessages] = useState<any[]>([]);
	const [sendOddsScasserLink, setSendOddsScasserLink] = useState<boolean>(false);
	const [isPremium, setIsPremium] = useState<boolean>(false);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [singleUploadedFile, setSingleUploadedFile] = useState<File>();
	const [uploadedFilesBase64, setUploadedFilesBase64] = useState<string[]>([]);
	const [fileLimit, setFileLimit] = useState(false);

	const [contentMini1, setContentMini1] = useState('');
	const [contentMini2, setContentMini2] = useState('');
	const [contentMini3, setContentMini3] = useState('');
	const [contentMini4, setContentMini4] = useState('');
	const [contentMini5, setContentMini5] = useState('');
	const [contentMini6, setContentMini6] = useState('');

	const [contentFull, setContentFull] = useState<string>('');
	const [instanceFull, setInstanceFull] = useState<any>();

	const [contentStandard, setContentStandard] = useState<string>('');
	const [instanceStandard, setInstanceStandard] = useState<any>();
	const [instanceMini1, setInstanceMini1] = useState<any>();
	const [instanceMini2, setInstanceMini2] = useState<any>();
	const [instanceMini3, setInstanceMini3] = useState<any>();
	const [instanceMini4, setInstanceMini4] = useState<any>();
	const [instanceMini5, setInstanceMini5] = useState<any>();
	const [instanceMini6, setInstanceMini6] = useState<any>();

	const [previewFull, setPreviewFull] = useState<string>('');
	const [previewFullCustom, setPreviewFullCustom] = useState<boolean>(false);
	const [previewStandard, setPreviewStandard] = useState<string>('');
	const [previewStandardCustom, setPreviewStandardCustom] = useState<boolean>(false);

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isAuthor = useHasRole('AUTORE');

	const singleFileInput = useRef<HTMLInputElement>(null);
	const multipleFilesInput = useRef<HTMLInputElement>(null);

	const dispatch = useAppDispatch();

	const clearSearchHandler = () => {
		setSearchInput('');
	};

	const searchChangeHandler = (searchValue: any) => {
		setSearchInput(searchValue.target.value);
	};

	const searchKeyHandler = (event: any) => {
		if (event.keyCode === 27) {
			clearSearchHandler();
		}
	};

	const unloadChannel = () => {
		setAlreadySentMessages([]);
	};

	const loadChannel = async (channelId: number) => {
		try {
			//instanceFull.editor.setData('');
			const messages = await getMessagesList(channelId);
			setAlreadySentMessages(messages.content);
		} catch (error: any) {
			setAlreadySentMessages([]);
			throw error;
		}
	};

	const handleClickChannel = (event: React.MouseEvent<HTMLImageElement>) => {
		event.preventDefault();
		const clickedChannel: HTMLImageElement = event.currentTarget;
		if (selectedChannel?.id.toString() === clickedChannel.id) {
			setSelectedChannel(undefined);
			unloadChannel();
		} else {
			const selected = filteredChannelsList.find((channel) => channel.id.toString() === clickedChannel.id);
			setSelectedChannel(selected);
			loadChannel(parseInt(selected.id));
			setIsPremium(false);
		}
	};

	const handleSendOddScasserLink = () => {
		setSendOddsScasserLink((prevState) => !prevState);
	};

	const handlePremium = () => {
		setIsPremium((prevState) => !prevState);
	};

	const handleUploadFiles = (files: File[]) => {
		const uploaded = [...uploadedFiles];
		let limitExceeded = false;
		files.some((file: File) => {
			if (uploaded.findIndex((f) => f.name === file.name) === -1) {
				uploaded.push(file);
				if (uploaded.length === MAX_COUNT) setFileLimit(true);
				if (uploaded.length > MAX_COUNT) {
					const snackbarData: SnackbarOptions = {};

					snackbarData.type = 'warning';
					snackbarData.message = `Puoi inviare un massimo di ${MAX_COUNT} immagini`;
					dispatch(addSnackbar(snackbarData));
					setFileLimit(false);
					limitExceeded = true;
					return true;
				}
			}
		});
		if (!limitExceeded) setUploadedFiles(uploaded);
	};

	const handleMultipleFilesChange = (e: any) => {
		const chosenFiles = Array.prototype.slice.call(e.target.files);
		handleUploadFiles(chosenFiles);
	};

	const handleMultipleFilesSelector = () => {
		if (multipleFilesInput.current) {
			multipleFilesInput.current.click();
		}
	};

	const handleRemoveFile = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const button: HTMLButtonElement = event.currentTarget;
		let uploaded = [...uploadedFiles];
		uploaded = uploaded.filter((selectedItem) => selectedItem.name !== button.name);
		setUploadedFiles(uploaded);
		setFileLimit(false);
		return true;
	};

	const handleSendButton = () => {
		// eslint-disable-next-line no-restricted-globals
		if (editingId == null || confirm('Stai per inviare la correzione per il messaggio #' + editingId)) {
			setIsSending(true);
		}
	};

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files === null) return;
		setSingleUploadedFile(files[0]);
		if (files && files.length > 0) {
			var oddScasserText = '';
			if (sendOddsScasserLink) {
				oddScasserText =
					"<br><p><a style='font-size: 25px;' href='" +
					getEnvironmentBaseUrl('/reservedArea/oddsScasser/quotemigliori') +
					"' target='_blank' >Trova le quote migliori con il nuovo OddsScasser!</a></p>";
			}
			// const fileB64 = await getFileContentInBase64([files[0]]);
			const fileFile = files[0];
			const fileIds = [{ id: await uploadFileFile(selectedChannel.id, fileFile) }];
			var message = {
				channelId: selectedChannel.id,
				content: '<p>File: ' + files[0].name + '</p>' + oddScasserText,
				preview: ('File: '+files[0].name).slice(0, 60),
				premiumContent: null,
				files: fileIds,
			};
			try {
				postMessage(message);
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'success';
				snackbarData.message = `Messaggio inviato con successo!`;
				dispatch(addSnackbar(snackbarData));
				setTimeout(() => {
					loadChannel(parseInt(selectedChannel.id));
				}, 3000);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nell'invio del messaggio!`;
				dispatch(addSnackbar(snackbarData));
				throw error;
			}
		}
	};

	const handleSendFile = () => {
		if (singleFileInput.current) {
			singleFileInput.current.click();
		}
	};

	const handleEditMessage = (idMessage: number) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente modificare il messaggio #' + idMessage + ' ???')) {
			const message: any = alreadySentMessages.find((message) => message.id === idMessage);
			const WARNING_MSG = '<p>ATTENZIONE: correzione messaggio inviato! [-#' + message.id + ']</p>';
			setEditingId(idMessage);
			if (message.files.length >= 1) {
				instanceMini1.editor.setData('<img src="' + message.files[0].url + '" />');
			}
			if (message.premiumContent !== null) {
				instanceFull.editor.setData(WARNING_MSG + message.premiumContent);
				setIsPremium(true);
			} else {
				instanceFull.editor.setData(WARNING_MSG + message.content);
				setIsPremium(false);
			}
		}
	};

	const handleStopEditing = () => {
		if (isSending === false) {
			setEditingId(null);
			instanceFull.editor.setData('');
		}
	};

	function preparePreview(htmlText: string, maxLenght: number): string {
		const parser = new DOMParser();
		const text = parser.parseFromString(htmlText, 'text/html').documentElement.innerText;
		const decodedText = he.decode(text);
		const trimmedText = decodedText.replace(/(\r\n|\n|\r)/gm," ");
		const suspenceText = trimmedText !== '' ? trimmedText.slice(0, maxLenght).trim() +"…" : "";
		return suspenceText;
	  }

	const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		if (name === "previewFull") {
			setPreviewFull(value);
			setPreviewFullCustom(true);
		}
		if (name === "previewStandard") {
			setPreviewStandard(value);
			setPreviewStandardCustom(true);
		}

	}

	const handleDeleteMessage = async (idMessage: number) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente cancellare il messaggio #' + idMessage + ' ???')) {
			try {
				await deleteMessage(idMessage);
			} catch (error: any) {
				throw error;
			}
			loadChannel(parseInt(selectedChannel.id));
		}
	};

	const getFileContentInBase64 = (files: File[]) => {
		const fileReadPromises = files.map((file) => {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result as string);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				reader.readAsDataURL(file);
			});
		});
		return Promise.all(fileReadPromises);
	};

	const getImgSrc = (html: string) => {
		const imgIndex = html.indexOf('<img');
		if (imgIndex === -1) {
			return '';
		}

		const imgTag = html.substring(imgIndex);
		const srcRegex = /src="([^"]+)"/;
		const srcMatch = imgTag.match(srcRegex);
		if (!srcMatch) {
			return '';
		}

		const srcValue = srcMatch[1];

		return srcValue;
	};

	const uploadB64 = async (channelId: number, fileB64: string, fileName: string): Promise<number | null> => {
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				formData.append('file', blob, fileName);
				uploaded = await uploadFile(channelId, formData);
			});
		return parseInt(uploaded.id);
	};

	const uploadFileFile = async (channelId: number, fileFile: File): Promise<number | null> => {
		if (typeof fileFile === 'undefined') return null;
		var uploaded: any;

		var formData = new FormData();
		formData.append('file', fileFile, fileFile.name);
		uploaded = await uploadFile(channelId, formData);

		return parseInt(uploaded.id);
	};

	function isImageFileName(fileName: string): boolean {
		const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
		return extensions.some((ext) => fileName.toLowerCase().endsWith(ext));
	}

	const createMessage = async () => {
		var filesId: any[] = [];

		for (const file of uploadedFiles) {
			try {
				var formData = new FormData();
				formData.append('file', file, file.name);
				var uploaded: any = await uploadFile(selectedChannel.id, formData);
				filesId.push({ id: uploaded.id });
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "C'è stato un problema nel caricamento dell'immagine!";
				dispatch(addSnackbar(snackbarData));
				setIsSending(false);
				throw error;
			}
		}

		// uploader.then(async () => {

		// Upload pictures from Mini Copy/Paste Editors
		var pasted1 = getImgSrc(contentMini1);
		var pasted2 = getImgSrc(contentMini2);
		var pasted3 = getImgSrc(contentMini3);
		var pasted4 = getImgSrc(contentMini4);
		var pasted5 = getImgSrc(contentMini5);
		var pasted6 = getImgSrc(contentMini6);

		var id1 = await uploadB64(selectedChannel.id, pasted1, 'pasted1');
		if (id1 !== null) {
			await filesId.push({ id: id1 });
		}
		var id2 = await uploadB64(selectedChannel.id, pasted2, 'pasted2');
		if (id2 !== null) {
			await filesId.push({ id: id2 });
		}
		var id3 = await uploadB64(selectedChannel.id, pasted3, 'pasted3');
		if (id3 !== null) {
			await filesId.push({ id: id3 });
		}
		var id4 = await uploadB64(selectedChannel.id, pasted4, 'pasted4');
		if (id4 !== null) {
			await filesId.push({ id: id4 });
		}
		var id5 = await uploadB64(selectedChannel.id, pasted5, 'pasted5');
		if (id5 !== null) {
			await filesId.push({ id: id5 });
		}
		var id6 = await uploadB64(selectedChannel.id, pasted6, 'pasted6');
		if (id6 !== null) {
			await filesId.push({ id: id6 });
		}

		// Sending the real message!

		var oddScasserText = '';
		if (sendOddsScasserLink) {
			oddScasserText =
				"<br><p><a style='font-size: 25px;' href='" +
				getEnvironmentBaseUrl('/reservedArea/oddsScasser/quotemigliori') +
				"' target='_blank' >Trova le quote migliori con il nuovo OddsScasser!</a></p>";
		}

		var message = {
			channelId: selectedChannel.id,
			content: isPremium ? contentStandard : contentFull + oddScasserText,
			preview: isPremium ? previewStandard : previewFull,
			premiumContent: isPremium ? contentFull + oddScasserText : null,
			premiumPreview: isPremium ? previewFull : null,
			files: filesId,
		};

		if (message.content !== '' || message.files.length > 0) {
			try {
				await postMessage(message);
				instanceFull.editor.setData('');
				if (editingId !== null) {
					try {
						await deleteMessage(editingId);
					} catch (error: any) {
						const snackbarData: SnackbarOptions = {};
						snackbarData.type = 'error';
						snackbarData.message = `C'è stato un problema nel cancellare il messaggio vecchio!`;
						dispatch(addSnackbar(snackbarData));
						setIsSending(false);
						throw error;
					}
				}
				setEditingId(null);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = `C'è stato qualche problema nell'invio del messaggio!`; //413 too large
				dispatch(addSnackbar(snackbarData));
				setIsSending(false);
				throw error;
			}

			setUploadedFiles([]);
			setContentMini1('');
			setContentMini2('');
			setContentMini3('');
			setContentMini4('');
			setContentMini5('');
			setContentMini6('');
			instanceMini1.editor.setData('');
			instanceMini2.editor.setData('');
			instanceMini3.editor.setData('');
			instanceMini4.editor.setData('');
			instanceMini5.editor.setData('');
			instanceMini6.editor.setData('');
			setFileLimit(false);
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'success';
			snackbarData.message = `Messaggio inviato con successo!`;
			dispatch(addSnackbar(snackbarData));
			setTimeout(() => {
				loadChannel(parseInt(selectedChannel.id));
			}, 3000);
		} else {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'warning';
			snackbarData.message = "Devi scrivere un messaggio o aggiungere un'immagine!" + contentStandard + '!!';
			dispatch(addSnackbar(snackbarData));
		}
		setIsSending(false);

		// });
	};

	useEffect(() => {
		setFilteredChannelsList(
			channelsList.filter((channel: any) => {
				if (channel.name.toLowerCase().includes(searchInput.toLowerCase())) return channel;
			})
		);
	}, [searchInput, channelsList]);

	useEffect(() => {
		if (isSending === true) createMessage();
	}, [isSending]);

	useEffect(() => {
		const xxx = async () => {
			const filesBase64 = await getFileContentInBase64(uploadedFiles);
			setUploadedFilesBase64(filesBase64);
		};
		xxx();
	}, [uploadedFiles]);

	useEffect(() => {
		const getChannels = async () => {
			const list = await getChannelsList();
			setChannelsList(list);
		};
		getChannels();
	}, []);

	useEffect(() => {
		if (!previewFullCustom) setPreviewFull(preparePreview(contentFull, 55));
	}, [contentFull]);

	useEffect(() => {
		if (!previewStandardCustom) setPreviewStandard(preparePreview(contentStandard, 55));
	}, [contentStandard]);

	const breadCrumbSettings: TitleBreadCrumbSettings = { subSectionText: 'Invio Notifiche' };

	if (!isSuperAdmin && !isAdmin && !isAuthor) return <></>;

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}>
				<SearchChannelBox>
					<SearchInput
						onKeyDown={searchKeyHandler}
						onChange={searchChangeHandler}
						value={searchInput}
					/>

					{searchInput !== '' && (
						<SearchClearButton onClick={clearSearchHandler}>
							<ClearButtonIconSVG />
						</SearchClearButton>
					)}

					<SearchButton>
						<SearchIconSVG />
					</SearchButton>
				</SearchChannelBox>
			</DashboardTitleSection>

			<BaseContentCard>
				<ChannelsBox>
					{filteredChannelsList.map((channel: any, index: number) => {
						return (
							<ChannelIcon isSelected={channel.id.toString() === selectedChannel?.id.toString()}>
								<img
									src={channel.icon ? channel.icon?.url : channelNoIconSVG}
									id={channel.id.toString()}
									alt={channel.name}
									title={channel.name}
									onClick={handleClickChannel}
								/>
							</ChannelIcon>
						);
					})}
				</ChannelsBox>

				{selectedChannel !== undefined && (
					<>
						<NotificationSendBox>
							<NotificationSendButton
								onClick={handleSendButton}
								disabled={isSending}
							>
								&#x2709; {editingId !== null && 'Re-'}Invia {isPremium && 'PREMIUM'} a <strong>{selectedChannel.name}</strong>
							</NotificationSendButton>

							{editingId !== null && <NotificationStopEditingButton onClick={handleStopEditing}>Annulla Modifica</NotificationStopEditingButton>}

							<NotificationSendSingleFile onClick={handleSendFile}>&#x21ea; Invia direttamente un file</NotificationSendSingleFile>
							<NotificationSingleFile
								ref={singleFileInput}
								onChange={handleSingleFileChange}
							/>
						</NotificationSendBox>

						<NotificationEditorBox>
							<OddsscasserLinkBox>
								<input
									type='checkbox'
									name='inviaLinkOddScasser'
									id='inviaLinkOddScasser'
									value=''
									onChange={handleSendOddScasserLink}
								/>
								Invia link OddScasser!
							</OddsscasserLinkBox>

							<UploadMultipleFilesBox>
								<UploadMultipleFilesTitle>
									Se vuoi allegare delle immagini .jpg .png (max. {MAX_COUNT} fino a 2MB per immagine) come messaggi{' '}
									<strong>consecutivi</strong> a quello di testo puoi selezionarle da qui:
								</UploadMultipleFilesTitle>

								<UploadMultipleFilesButtonBox>
									<UploadMultipleFilesSelector
										onClick={handleMultipleFilesSelector}
										disabled={fileLimit}
									>
										Scegli una o più immagini
									</UploadMultipleFilesSelector>
								</UploadMultipleFilesButtonBox>

								<UploadMultipleFiles
									ref={multipleFilesInput}
									onChange={handleMultipleFilesChange}
									disabled={fileLimit}
								/>

								<UploadMultipleFilesList>
									{uploadedFiles.map((file) => (
										<UploadMultipleFilesItem>
											{file.name}
											<UploadMultipleFilesRemoveButton
												onClick={handleRemoveFile}
												name={file.name}
											>
												&#10006;
											</UploadMultipleFilesRemoveButton>
										</UploadMultipleFilesItem>
									))}
								</UploadMultipleFilesList>

								<UploadMultipleFilesBoxTitle>Oppure puoi incollarle o trascinarle qui:</UploadMultipleFilesBoxTitle>
							</UploadMultipleFilesBox>

							<PasteImagesBox>
								<MiniEditBox>
									{ !contentMini1 && <CKEditor
										onChange={(evt) => {
											setContentMini1(evt.editor.getData());
										}}
										initData=''
										onInstanceReady={(editor) => {
											setInstanceMini1(editor);
										}}
										config={{
											language: 'it',
											height: '100px',
											removeButtons: 'Image',
											removePlugins: 'resize',
											toolbar: [],
											allowedContent: 'img(left,right)[!src,alt,width,height];',
										}}
									/> }

									{ contentMini1 !== '' && <MiniPreviewBox><MiniPreview src={getImgSrc(contentMini1)} /></MiniPreviewBox>}

									{ contentMini1 !== '' && <ClearMiniEditButton title="Cancella" onClick={() => setContentMini1('')}>&#10006;</ClearMiniEditButton>}
								</MiniEditBox>

								<MiniEditBox>
									{ !contentMini2 && <CKEditor
										onChange={(evt) => {
											setContentMini2(evt.editor.getData());
										}}
										initData=''
										onInstanceReady={(editor) => {
											setInstanceMini2(editor);
										}}
										config={{
											language: 'it',
											height: '100px',
											removeButtons: 'Image',
											removePlugins: 'resize',
											toolbar: [],
											allowedContent: 'img(left,right)[!src,alt,width,height];',
										}}
									/> }

									{ contentMini2 !== '' && <MiniPreviewBox><MiniPreview src={getImgSrc(contentMini2)} /></MiniPreviewBox>}

									{ contentMini2 !== '' && <ClearMiniEditButton title="Cancella" onClick={() => setContentMini2('')}>&#10006;</ClearMiniEditButton>}
								</MiniEditBox>

								<MiniEditBox>
									{ !contentMini3 && <CKEditor
										onChange={(evt) => {
											setContentMini3(evt.editor.getData());
										}}
										initData=''
										onInstanceReady={(editor) => {
											setInstanceMini3(editor);
										}}
										config={{
											language: 'it',
											height: '100px',
											removeButtons: 'Image',
											removePlugins: 'resize',
											toolbar: [],
											allowedContent: 'img(left,right)[!src,alt,width,height];',
										}}
									/> }

									{ contentMini3 !== '' && <MiniPreviewBox><MiniPreview src={getImgSrc(contentMini3)} /></MiniPreviewBox>}

									{ contentMini3 !== '' && <ClearMiniEditButton title="Cancella" onClick={() => setContentMini3('')}>&#10006;</ClearMiniEditButton>}
								</MiniEditBox>

								<MiniEditBox>
									{ !contentMini4 && <CKEditor
										onChange={(evt) => {
											setContentMini4(evt.editor.getData());
										}}
										initData=''
										onInstanceReady={(editor) => {
											setInstanceMini4(editor);
										}}
										config={{
											language: 'it',
											height: '100px',
											removeButtons: 'Image',
											removePlugins: 'resize',
											toolbar: [],
											allowedContent: 'img(left,right)[!src,alt,width,height];',
										}}
									/> }

									{ contentMini4 !== '' && <MiniPreviewBox><MiniPreview src={getImgSrc(contentMini4)} /></MiniPreviewBox>}
									
									{ contentMini4 !== '' && <ClearMiniEditButton title="Cancella" onClick={() => setContentMini4('')}>&#10006;</ClearMiniEditButton>}
								</MiniEditBox>

								<MiniEditBox>
									{ !contentMini5 && <CKEditor
										onChange={(evt) => {
											setContentMini5(evt.editor.getData());
										}}
										initData=''
										onInstanceReady={(editor) => {
											setInstanceMini5(editor);
										}}
										config={{
											language: 'it',
											height: '100px',
											removeButtons: 'Image',
											removePlugins: 'resize',
											toolbar: [],
											allowedContent: 'img(left,right)[!src,alt,width,height];',
										}}
									/> }

									{ contentMini5 !== '' && <MiniPreviewBox><MiniPreview src={getImgSrc(contentMini5)} /></MiniPreviewBox>}

									{ contentMini5 !== '' && <ClearMiniEditButton title="Cancella" onClick={() => setContentMini5('')}>&#10006;</ClearMiniEditButton>}
								</MiniEditBox>

								<MiniEditBox>
									{ !contentMini6 && <CKEditor
										onChange={(evt) => {
											setContentMini6(evt.editor.getData());
										}}
										initData=''
										onInstanceReady={(editor) => {
											setInstanceMini6(editor);
										}}
										config={{
											language: 'it',
											height: '100px',
											removeButtons: 'Image',
											removePlugins: 'resize',
											toolbar: [],
											allowedContent: 'img(left,right)[!src,alt,width,height];',
										}}
									/> }
									
									{ contentMini6 !== '' && <MiniPreviewBox><MiniPreview src={getImgSrc(contentMini6)} /></MiniPreviewBox>}

									{ contentMini6 !== '' && <ClearMiniEditButton title="Cancella" onClick={() => setContentMini6('')}>&#10006;</ClearMiniEditButton>}
								</MiniEditBox>
							</PasteImagesBox>

							<MainTextEditorBox>
								{selectedChannel.premiumProductId !== null && (
									<PremiumBox>
										<input
											type='checkbox'
											name='inviaLinkOddScasser'
											id='inviaLinkOddScasser'
											value=''
											checked={isPremium}
											onChange={handlePremium}
										/>
										messaggio PREMIUM
									</PremiumBox>
								)}

								<MainTextEditorTitle>Scrivi qui il testo del messaggio{isPremium && ' PREMIUM'}:</MainTextEditorTitle>

								<CKEditor
									onChange={(evt) => {
										setContentFull(evt.editor.getData());
									}}
									initData=''
									onInstanceReady={(editor) => {
										setInstanceFull(editor);
									}}
									config={{
										language: 'it',
										height: '400px',
										removeButtons: 'Image',
										removePlugins: 'resize',
										extraPlugins: 'emoji',
									}}
								/>

								<PreviewBox>
									<PreviewTitle>Testo breve notifica sul telefono:</PreviewTitle>

									<InputPreview 
										name='previewFull'
										onChange={handlePreviewChange}
										value={previewFull}
									/>
								</PreviewBox>

								{isPremium !== false && (
									<>
										<MainTextEditorTitle>Scrivi qui il testo del messaggio STANDARD:</MainTextEditorTitle>

										<CKEditor
											onChange={(evt) => {
												setContentStandard(evt.editor.getData());
											}}
											initData={selectedChannel.defaultMessage}
											onInstanceReady={(editor) => {
												setInstanceStandard(editor);
												setContentStandard(selectedChannel.defaultMessage);
											}}
											config={{
												language: 'it',
												height: '200px',
												removeButtons: 'Image',
												removePlugins: 'resize',
												extraPlugins: 'emoji',
											}}
										/>

										<PreviewBox>
											<PreviewTitle>Testo breve notifica sul telefono:</PreviewTitle>

											<InputPreview 
												name='previewStandard'
												onChange={handlePreviewChange}
												value={previewStandard}
											/>
										</PreviewBox>
									</>
								)}
							</MainTextEditorBox>
						</NotificationEditorBox>

						<NotificationSendBox>
							<NotificationSendButton
								onClick={handleSendButton}
								disabled={isSending}
							>
								&#x2709; {editingId !== null && 'Re-'}Invia {isPremium && 'PREMIUM'} a <strong>{selectedChannel.name}</strong>
							</NotificationSendButton>

							{editingId !== null && <NotificationStopEditingButton onClick={handleStopEditing}>Annulla Modifica</NotificationStopEditingButton>}
						</NotificationSendBox>

						{alreadySentMessages.length > 0 && (
							<>
								<NotificationHistoryTitle>Ultime notifiche inviate</NotificationHistoryTitle>

								<NotificationHistoryBox>
									{alreadySentMessages.map((message: any, index: number) => {
										return (
											<>
												{message.files
													.slice()
													.reverse()
													.map((file: any, index: number) => {
														return (
															<>
																{isImageFileName(file.url) ? (
																	<NotificationHistoryMessageImage
																		src={file.url}
																		alt={file.id}
																	/>
																) : ( <><p>Link al file:<br/>&nbsp;</p>
																	<p>
																	<a
																		target='_blank'
																		href={file.url}
																		rel='noreferrer'
																	>
																		{file.name}
																	</a></p></>
																)}
															</>
														);
													})}
												<NotificationHistoryMessage>
													{message.premiumContent !== null && (
														<NotificationHistoryMessagePremiumFlag>PREMIUM:&nbsp;</NotificationHistoryMessagePremiumFlag>
													)}
													<NotificationHistoryMessageContent dangerouslySetInnerHTML={{__html: message.premiumContent !== null ? message.premiumContent : message.content}}>
													</NotificationHistoryMessageContent>
													<NotificationHistoryMessageDate>
														{new Date(message.sendingDate).toLocaleString('it-IT').split(':').slice(0, -1).join(':')}
													</NotificationHistoryMessageDate>
													<NotificationHistoryMessageEditButton
														onClick={() => handleEditMessage(message.id)}
														title='Modifica'
													>
														<EditIconSVG />
													</NotificationHistoryMessageEditButton>
													<NotificationHistoryMessageDeleteButton
														onClick={async () => handleDeleteMessage(message.id)}
														title='Elimina'
													>
														&#10006;
													</NotificationHistoryMessageDeleteButton>
												</NotificationHistoryMessage>
											</>
										);
									})}
									<></>
								</NotificationHistoryBox>
							</>
						)}
					</>
				)}
			</BaseContentCard>
		</Page>
	);
};

export default SendNotificationsPage;
