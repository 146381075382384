import { FC } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//types
import { CategoriesProps } from './Categories.component.d';

//components
import Category from './Category';

//style
import { CategoriesBox } from './style/Categories.component.style';
import { ColumnBox } from 'pages/UserDetailPage/style/UserDetailPage.component.style';
import SubCategory from '../SubCategories/SubCategory';

const Categories: FC<CategoriesProps> = ({ product, putProduct, isReadOnly }) => {
	const allCategories = useAppSelector((state) => state.global.allProductCategories);
	const allSubCategories = useAppSelector((state) => state.global.allProductSubCategories);

	const sortedCategories = Array.isArray(allCategories) ? [...allCategories].sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)) : allCategories;

	const sortedSubCategories = Array.isArray(allSubCategories)
		? [...allSubCategories].sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
		: allSubCategories;

	return (
		<CategoriesBox>
			{sortedCategories?.map((category, index) => {
				return (
					<ColumnBox key={index + '_column'}>
						<Category
							key={index + '_category'}
							category={category}
							product={product}
							putProduct={putProduct}
							isReadOnly={isReadOnly}
						/>
						{sortedSubCategories
							?.filter((s) => s.categoryId === category.id)
							.map((subCategory, index) => {
								return (
									<SubCategory
										key={index}
										product={product}
										subCategory={subCategory}
										putProduct={putProduct}
										isReadOnly={isReadOnly}
									></SubCategory>
								);
							})}
					</ColumnBox>
				);
			})}
		</CategoriesBox>
	);
};

export default Categories;
