import { FC } from 'react';

//types
import { ProductTabProps } from './ProductTab.component.d';

//components
import CourseGenericInfo from './GenericInfo';
import BaseFieldset from 'components/ui/BaseFieldset';
import Categories from './Categories';
import SubscriptionLimit from './SubscriptionLimit';
import ThematicBroadcastTypes from './ThematicBroadcastTypes';
import IsPinnedBroadcast from './IsPinned';
import Multibrand from './Multibrand';

//style
import { MainBox, RowBox, RowBoxLeft } from './style/ProductTab.component.style';


const CourseTab: FC<ProductTabProps> = ({ product, putProduct, updateProductData, isReadOnly }) => {

	var thematicBroadcastStatus = "";
	if (product.type === 'THEMATIC_BROADCAST') {
		if (product.productThematicCall.status === "CREATED") thematicBroadcastStatus += " (Creata)";
		if (product.productThematicCall.status === "SCHEDULED") {
			thematicBroadcastStatus += (new Date(product.productThematicCall.liveDate) >= (new Date())) ? " (Pubblicata)" : " (Fatta)";
		}
		if (product.productThematicCall.status === "PUBLISHED") thematicBroadcastStatus += " (Link caricato)";
	}

	return (
		<MainBox>
			<RowBox>
				<BaseFieldset legend={'INFO'+thematicBroadcastStatus}>
					<CourseGenericInfo
						product={product}
						putProduct={putProduct}
						updateProductData={updateProductData}
						isReadOnly={isReadOnly}
					/>
				</BaseFieldset>
				<BaseFieldset legend='POSTI LIMITATI'>
					<SubscriptionLimit
						product={product}
						putProduct={putProduct}
						isReadOnly={isReadOnly}
					/>
				</BaseFieldset>
			</RowBox>
			<RowBox>
				<BaseFieldset legend='CATEGORIE'>
					<RowBoxLeft>
						<Categories
							product={product}
							putProduct={putProduct}
							isReadOnly={isReadOnly}
						/>
					
					{ product.type==='THEMATIC_BROADCAST' && 
						<>
							<ThematicBroadcastTypes
								product={product}
								putProduct={putProduct}
								isReadOnly={isReadOnly}
							/>
							{/* <IsPinnedBroadcast
								product={product}
								putProduct={putProduct}
							/> */}
						</>
					}
					</RowBoxLeft>
				</BaseFieldset>
			</RowBox>
			<RowBox>
				<BaseFieldset legend='MULTIBRAND'>
					<Multibrand
						product={product}
						putProduct={putProduct}
						isReadOnly={isReadOnly}
					/>
				</BaseFieldset>
			</RowBox>
		</MainBox>
	);
};

export default CourseTab;
