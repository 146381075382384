import { FC, useState, useEffect, ChangeEvent, useRef } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import { useParams, useSearchParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import useAppSelector from 'hooks/useAppSelector';
import useHasRole from 'hooks/useHasRole';
import useHasPermission from 'hooks/useHasPermission';
import useBrand from 'hooks/useBrand';

//types
import { RoadmapEventDetailPageProps } from './RoadmapEventDetailPage.component.d';
import { TitleBreadCrumbSettings } from 'components/DashboardTitleSection/DashboardTitleSection.component.d';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { RoadmapEventType } from 'types/RoadmapEventType';

//assets
import { ReactComponent as EditIconSVG } from './assets/editIcon.svg';

//components
import DashboardTitleSection from 'components/DashboardTitleSection';
import EditableInput from 'components/EditableComponents/EditableInput';
import CheckboxInput from 'components/ui/CheckboxInput';
import Visible from './Visible';

//utils
import { getRoadmapEventFromId, getRoadmapEventTypes, putRoadmapEvent, uploadFile, deleteFile } from 'services/roadmapevent.services';
import isEqual from 'lodash/isEqual';

//style
import { Row, Col } from 'style/layout';
import { Page, BaseContentCard, InfoLabel, EditableArea, SubscriptionLabel, SubscriptionMaxDate, HoursDiv, HoursSelect, ProductImage, ProductImageContainer, ProductImageNewFile, ImageEditIconOverlay, PicButton } from './style/RoadmapEventDetailPage.component.style';

const RoadmapEventDetailPage: FC<RoadmapEventDetailPageProps> = () => {
	const [roadmapevent, setRoadmapEvent] = useState<RoadmapEvent>({} as RoadmapEvent);
	const [roadmapeventTypes, setRoadmapEventTypes] = useState<RoadmapEventType[]>([]);
	const [subPath, setSubPath] = useState<string | undefined>('');
	const [localLiveDate, setLocalLiveDate] = useState<Date>();
	const [localLiveHour, setLocalLiveHour] = useState(-1);
	const [localLiveMinute, setLocalLiveMinute] = useState(-1);
	const [localAbsoluteLiveDate, setLocalAbsoluteLiveDate] = useState('');
	const [dateIsWithoutTime, setDateIsWithoutTime] = useState<boolean>(false);
	const [imageIsClickable, setImageIsClickable] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasChanged, setHasChanged] = useState<boolean>(false);

	const brand = useBrand();

	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isAdmin = useHasRole('ADMIN');
	const isRoadmap = useHasRole('ROADMAP-'+brand?.code);

	const productImageNewFileInput = useRef<HTMLInputElement>(null);

	const roadmapeventsList: RoadmapEvent[] = [];


	const { eventId } = useParams();
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();
	const numericId: number = eventId ? +eventId : 0;

	const isValidDate = (dateToCheck: Date | string): boolean => {
		if (!dateToCheck) return false;
		try {
			const testDate = new Date(dateToCheck);
			return true;
		} catch (err) {
			return false;
		}
	};

	const convertDate = (dateToConvert: string): string => {
		const [parcialDate, parcialTime] = dateToConvert.split(", ");
		const [dday, dmonth, dyear] = parcialDate.split("/");
		const [dhours, dminutes, desconds] = parcialTime.split(":");
		const convertedDate = `${dyear}-${dmonth.padStart(2, "0")}-${dday.padStart(2, "0")}T${dhours.padStart(2, "0")}:${dminutes}:${desconds}`;
		return convertedDate;
	}

	const getRoadmapEvent = async (numericId: number) => {
		if (!brand) return;
		if (numericId === 0) return;
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getRoadmapEventFromId(numericId, brand.code);
			setRoadmapEvent(response);
			if (response.beginDate && new Date(response.beginDate).getHours()>=0 && new Date(response.beginDate).getHours()<=3) setDateIsWithoutTime(true)
			setIsLoading(false);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca degli eventi';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const putUpdateEvent = async (updatedEvent: Partial<RoadmapEvent>) => {
		if (!brand) return;
		if (!updatedEvent.id) return;
		if (updatedEvent.visible === undefined) return;
		try {
			await putRoadmapEvent(updatedEvent, brand.code);
			setRoadmapEvent({...roadmapevent, visible: updatedEvent.visible});
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento visibilità evento";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const saveRoadmapEvent = async () => {
		if (!brand) return;
		const snackbarData: SnackbarOptions = {};
		if (roadmapevent.name.trim() === '') {
			snackbarData.type = 'error';
			snackbarData.message = "Devi specificare un nome per poter salvare.";
			dispatch(addSnackbar(snackbarData));
			return;
		}

		try {
			var localEvent: RoadmapEvent = roadmapevent;
			if (localLiveDate) {
				var subDate: Date = localLiveDate;
				if (dateIsWithoutTime) {
					subDate.setHours(1);
					subDate.setMinutes(0);
				} else {
					subDate.setHours(localLiveHour);
					subDate.setMinutes(localLiveMinute);
				}
				subDate.setSeconds(0);
				localEvent.beginDate = subDate.toISOString();
			}
			await putRoadmapEvent(localEvent, brand.code);
			setHasChanged(false);
			snackbarData.type = 'success';
			snackbarData.message = "Evento aggiornato con successo";
			dispatch(addSnackbar(snackbarData));
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio dell'evento";
			dispatch(addSnackbar(snackbarData));
		}
	};

	const getRoadmapTypes = async () => {
		if (!brand) return;
		if (numericId === 0) return;
		const snackbarData: SnackbarOptions = {};

		try {
			const response = await getRoadmapEventTypes(brand.code);
			setRoadmapEventTypes(response);
		} catch (error: any) {
			snackbarData.type = 'error';
			snackbarData.message = 'Errore nella ricerca dei tipi evento';
			dispatch(addSnackbar(snackbarData));
			if (!isMounted()) return;
		}
	};

	const saveLocalRoadmapEvent = (inputId: string, value: string) => {
		var localEvent: RoadmapEvent = { ...roadmapevent };
		if (inputId === 'name') localEvent.name = value;
		if (inputId === 'link') localEvent.link = value;
		if (!isEqual(localEvent, roadmapevent)) setHasChanged(true);
		setRoadmapEvent(localEvent);
	}

	const handleOnChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (!roadmapeventTypes) return; 
		const newType: RoadmapEventType | undefined = roadmapeventTypes.find((ty) => Number(event.currentTarget.value) === ty.id);
		if (!newType) return;
		var localEvent: RoadmapEvent = { ...roadmapevent };
		localEvent.eventType = { id: newType.id } as RoadmapEventType;
		if (!isEqual(localEvent, roadmapevent)) setHasChanged(true);
		setRoadmapEvent(localEvent);
	}

	const handleOnChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
		var localEvent: RoadmapEvent = { ...roadmapevent };
		localEvent.description = event.target.value;
		if (!isEqual(localEvent, roadmapevent)) setHasChanged(true);
		setRoadmapEvent(localEvent);
	};

	const onLiveDateChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => { 
		if (!isValidDate(event.currentTarget.value)) return;
		var subDate = new Date(event.currentTarget.value);
		var year = subDate.getFullYear().toString();
		const month = (subDate.getMonth() + 1).toString().padStart(2, '0'); 
		const day = subDate.getDate().toString().padStart(2, '0');
		const isoDate = `${year.padEnd(4,'0')}-${month}-${day}`;
		setLocalLiveDate(subDate);
		setLocalAbsoluteLiveDate(isoDate);
		setHasChanged(true);
	};

	const onLiveHourChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => { 
		setLocalLiveHour(Number(event.currentTarget.value));
		if ((!localLiveDate) || (!isValidDate(localLiveDate))) return;
		const subDate = new Date(localLiveDate);
		subDate.setHours(Number(event.currentTarget.value));
		subDate.setMinutes(localLiveMinute);
		subDate.setSeconds(0);
		setHasChanged(true);
	};

	const onLiveMinuteChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => { 
		setLocalLiveMinute(Number(event.currentTarget.value));
		if ((!localLiveDate) || (!isValidDate(localLiveDate))) return;
		const subDate = new Date(localLiveDate);
		subDate.setHours(localLiveHour);
		if (Number(event.currentTarget.value) >= 0) subDate.setMinutes(Number(event.currentTarget.value));
		subDate.setSeconds(0);
		if (Number(event.currentTarget.value) < 0) subDate.setSeconds(1);
		setHasChanged(true);
	};

	const handleOnChangeTBD = (nowIsChecked: boolean) => {
		var localEvent: RoadmapEvent = { ...roadmapevent};
		localEvent.tbd = nowIsChecked;
		setRoadmapEvent(localEvent);
		setHasChanged(true);
	}

	const handleOnChangeDateIsWithoutTime = (nowIsChecked: boolean) => {
		setDateIsWithoutTime(nowIsChecked);
		setHasChanged(true);
	}

	const uploadB64 = async (productId: number, fileB64: string, fileName: string): Promise<any | null> => {
		if (!brand) return;
		if (typeof fileB64 === 'undefined') return null;
		if (fileB64.length === 0) return null;
		var uploaded: any;
		await fetch(fileB64)
			.then((res) => res.blob())
			.then(async (blob) => {
				var formData = new FormData();
				formData.append('file', blob, fileName);
				uploaded = await uploadFile(productId, formData, brand.code);
			});
		return uploaded;
	};

	const getFileContentInBase64 = (files: File[]) => {
		const fileReadPromises = files.map((file) => {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result as string);
				};
				reader.onerror = (error) => {
					reject(error);
				};
				reader.readAsDataURL(file);
			});
		});
		return Promise.all(fileReadPromises);
	};

	const handleSingleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			const fileB64: string[] = await getFileContentInBase64([files[0]]);
			var uploaded = await uploadB64(roadmapevent.id, fileB64[0], files[0].name);
			if (uploaded) {
				var partial: Partial<RoadmapEvent> = { id: roadmapevent.id, customImage: uploaded };
				var localEvent: RoadmapEvent = { ...roadmapevent };
				localEvent.customImage = uploaded;
				setRoadmapEvent(localEvent);
			}
		}
	};

	const onImageOver = () => {
		setImageIsClickable(true);
	};

	const onImageOut = () => {
		setImageIsClickable(false);
	};

	const onImageClick = (bypass?: Boolean) => {
		if (imageIsClickable || bypass) {
			if (productImageNewFileInput.current) {
				productImageNewFileInput.current.click();
			}
		}
	};

	const onRemoveImageClick = async () => {
		if (!brand) return;
		if (window.confirm(`Rimuovere l'immagine dell'evento?`)) {
			try {
				await deleteFile(roadmapevent.id, brand.code);
				var localEvent: RoadmapEvent = { ...roadmapevent};
				localEvent.customImage = null;
				setRoadmapEvent(localEvent);
			} catch (error: any) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "Errore durante la rimozione dell'immagine";
				dispatch(addSnackbar(snackbarData));
			}
		}
	}

	useEffect(() => {
		if (!brand) return;
		getRoadmapEvent(numericId);
		getRoadmapTypes();
	}, [numericId, brand]);

	useEffect(() => {
		if (!isLoading) setSubPath((subPath) => 'Evento / ' + eventId?.toString() + ' | ' + roadmapevent?.name);
	}, [isLoading, roadmapevent, eventId]);

	useEffect(() => {
		if (roadmapevent?.beginDate) {
			const fixedDate = ((typeof roadmapevent?.beginDate === "string") && (roadmapevent?.beginDate.slice(-1) === 'Z')) ? roadmapevent?.beginDate : roadmapevent?.beginDate+'Z';
			const utcDate = new Date(fixedDate);
			const year = utcDate.getFullYear();
			const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
			const day = utcDate.getDate().toString().padStart(2, '0');
			const isoDate = `${year}-${month}-${day}`;
			const onlyDate = new Date(isoDate);

			if (!isValidDate(utcDate)) return;
    		const localDatetimeString = convertDate(utcDate.toLocaleString());
			setLocalLiveDate(new Date(onlyDate));
			setLocalAbsoluteLiveDate(isoDate);
			setLocalLiveHour((new Date(localDatetimeString)).getHours());
			setLocalLiveMinute((new Date(localDatetimeString)).getMinutes());
		}
	}, [roadmapevent?.beginDate]);

	const breadCrumbSettings: TitleBreadCrumbSettings = {
		subSectionText: 'Gestione Roadmap',
		subSubSectionText: subPath,
		subSectionLink: '/reservedArea/roadmap',
	};

	if (!isSuperAdmin && !isRoadmap) return (<></>);

	const typesSelector = (<select onChange={handleOnChangeType}>
		{roadmapeventTypes.map((ty) => { return <option value={ty.id} selected={ty.id === roadmapevent?.eventType?.id}>{ty.name}</option>})}
	</select>);

	return (
		<Page>
			<DashboardTitleSection breadCrumbSettings={breadCrumbSettings}></DashboardTitleSection>

			{isLoading && <>Loader</>}
			{!isLoading && (
			<BaseContentCard>
				<Row>
					<Col width="50%" margin="20px">
						<Row>
							<Visible
								eventId={roadmapevent.id}
								visible={roadmapevent.visible}
								putEvent={putUpdateEvent}
							/>
						</Row>
						<Row>
							<InfoLabel>Nome:</InfoLabel>

							<EditableInput
								inputId={'name'}
								editableInput={roadmapevent.name}
								update={saveLocalRoadmapEvent}
								placeholder='Inserisci il nome'
							/>
						</Row>
						<Row>
							<InfoLabel>Tipo:</InfoLabel>

							{typesSelector}
						</Row>
						<Row>
							<InfoLabel>Descrizione:</InfoLabel>

							<EditableArea
								value={roadmapevent.description}
								onChange={handleOnChangeDescription}
							/>
						</Row>
						<Row>
							<InfoLabel>Link:</InfoLabel>

							<EditableInput
								inputId={'link'}
								editableInput={roadmapevent.link}
								update={saveLocalRoadmapEvent}
								placeholder='Incolla il link'
							/>
						</Row>
					</Col>
					<Col width="50%" margin="20px">
						
						<Row gap='1rem'>
							<SubscriptionLabel>Data e Ora:</SubscriptionLabel>
							<SubscriptionMaxDate
								type='date'
								// value={(localLiveDate && isValidDate(localLiveDate)) ? localLiveDate?.toISOString().substring(0, 10) : ''}
								value={localAbsoluteLiveDate}
								onChange={onLiveDateChangeHandler}
								disabled={roadmapevent?.tbd}
							/>
							<Col><Row>
							<select id="hourSelect" onChange={onLiveHourChangeHandler} disabled={roadmapevent?.tbd || dateIsWithoutTime}>
								<option key={-1} value={-1}>--</option>
								{Array.from({ length: 24 }, (_, index) => index).map((hour) => (
								<option 
									key={hour} 
									value={hour}
									selected={(localLiveDate && isValidDate(localLiveDate) && (localLiveHour === hour)) ? true : false }
								>
									{hour < 10 ? `0${hour}` : hour}
								</option>
								))}
							</select>
							<HoursDiv>:</HoursDiv>
							<select id="minuteSelect" onChange={onLiveMinuteChangeHandler} disabled={roadmapevent?.tbd || dateIsWithoutTime}>
								<option key={-1} value={-1}>--</option>
								{Array.from({ length: 4 }, (_, index) => index * 15).map((minute) => (
								<option 
									key={minute} 
									value={minute}
									selected={(localLiveDate && isValidDate(localLiveDate) && (localLiveMinute === minute)) ? true : false }
								>
									{minute < 10 ? `0${minute}` : minute}
								</option>
								))}
							</select>
							<HoursDiv><small>Tuo orario locale(!)</small></HoursDiv>
							</Row></Col>

						</Row>

						<Row>
							<InfoLabel>Senza ora:</InfoLabel>

							<CheckboxInput
								label={"Senza ora"}
								isChecked={(dateIsWithoutTime === true) || (roadmapevent?.tbd === true)}
								onChange={(event) => handleOnChangeDateIsWithoutTime(event.valueOf())}
								inputName='DIWT'
							/>
						</Row>

						<Row>
							<InfoLabel>TBD:</InfoLabel>

							<CheckboxInput
								label={"Senza data"}
								isChecked={roadmapevent?.tbd === true}
								onChange={(event) => handleOnChangeTBD(event.valueOf())}
								inputName='TBD'
							/>
						</Row>

						<Row>
							<InfoLabel>Immagine:</InfoLabel>

							<ProductImageContainer>
								<ProductImage
									src={roadmapevent.customImage?.url ? roadmapevent.customImage.url : ""}
									onMouseOver={onImageOver}
									onMouseOut={onImageOut}
									onClick={() => onImageClick(false)}
								/>

								<ImageEditIconOverlay style={{ opacity: imageIsClickable ? 1 : 0 }}>
									<EditIconSVG />
								</ImageEditIconOverlay>
							</ProductImageContainer>
							{!roadmapevent.customImage?.url &&
								<PicButton onClick={() => onImageClick(true)}>Carica</PicButton>
							}
							{roadmapevent.customImage?.url &&
								<PicButton onClick={onRemoveImageClick}>Rimuovi</PicButton>
							}

							<ProductImageNewFile
								ref={productImageNewFileInput}
								onChange={handleSingleFileChange}
							/>
						</Row>
					</Col>
				</Row>
				<Row>
					<button onClick={saveRoadmapEvent} disabled={!hasChanged}>SAVE</button>
				</Row>
			</BaseContentCard>
			)}
		</Page>
	);
};

export default RoadmapEventDetailPage;
