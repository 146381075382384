import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { getEnvironmentTestBaseUrl } from 'configs/config.helper';


//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useBrand from 'hooks/useBrand';

//types
import { MultibrandProps } from './Multibrand.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { Brand } from 'types/Brand';
import { Product } from 'types/Product';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { RoadmapEventType } from 'types/RoadmapEventType';

//config
import { getBrandsList } from 'services/brand.services';

//utils
import { assignBrandToProduct } from './services/Multibrand.services';
import { getRoadmapEvents, getRoadmapEventTypes, addEvent } from '../../../RoadmapPage/services/RoadmapPage.services';
import { putRoadmapEvent } from 'services/roadmapevent.services';

//style
import { MainBox, BrandCheckbox, BrandItem, ButtonSave } from './style/Multibrand.component.style';
import { getCurrentAuthUser } from 'services/user.services';

const Multibrand: FC<MultibrandProps> = ({ product, putProduct, isReadOnly }) => {
    const [localProduct, setLocalProduct] = useState<Product>(product);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [originalRoadmapEvent, setOriginalRoadmapEvent] = useState<RoadmapEvent>({} as RoadmapEvent);
	const [originalRoadmapTypesList, setOriginalRoadmapTypesList] = useState<RoadmapEventType[]>();
    
    const currentBrand = useBrand();
    const dispatch = useAppDispatch();

    const handleBrandChange = (brand: Brand) => {
        if (selectedBrands.map((b: Brand) => b.code).includes(brand.code)) {
            setSelectedBrands(selectedBrands.filter(b => b.code !== brand.code));
        } else {
            setSelectedBrands([...selectedBrands, brand]);
        }
    };

    const sendToRoadmap = async () => {
		try {
			console.log("2ROADMAP: sendToRoadmap");
			if (!currentBrand) {
				throw new Error('brand not defined');
				console.log("2ROADMAP: brand not defined");
			}
			if (!originalRoadmapTypesList) {
				throw new Error('originalRoadmapTypesList not defined');
				console.log("2ROADMAP: originalRoadmapTypesList not defined");
			}
			const link = `${getEnvironmentTestBaseUrl(
			product?.productThematicCall.type === 'ESPERIENZA' ? 
			`app/public/product-esperienza/${product.id}`.replace('backoffice.', '') :
			`app/reservedArea/product-diretta-tematica/${product.id}`).replace('backoffice.', '')}`;
			console.log("2ROADMAP: link:",link);
			let roadmapEvent = {} as Partial<RoadmapEvent>;
			console.log("PRE: ",roadmapEvent);
			console.log("2ROADMAP: roadmapEvent:",roadmapEvent);
			const snackbarData: SnackbarOptions = {};
			if ((Object.keys(originalRoadmapEvent).length > 0) && originalRoadmapEvent.id) {
				console.log("2ROADMAP UPD: ",roadmapEvent);
				roadmapEvent.id = originalRoadmapEvent.id;
				roadmapEvent.brands = selectedBrands.map((brand: Brand) => ({ id: brand.id, code: brand.code })) as Brand[];
				await putRoadmapEvent(roadmapEvent, currentBrand.code);
				snackbarData.message = ((product.productThematicCall.type === 'ESPERIENZA') ? "Esperienza" : "Aula")+" roadmap aggiornata con successo";	
                snackbarData.type = 'success';
                dispatch(addSnackbar(snackbarData));
            } 	
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "C'è stato un problema nell'aggiornare su roadmap la "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula");
			dispatch(addSnackbar(snackbarData));
		}
	}

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const saveBrands: Partial<Brand>[] = selectedBrands.map((brand: Brand) => ({id: brand.id, code: brand.code}));
            const saved = await assignBrandToProduct(localProduct.id.toString(), saveBrands);
            var upProduct: Product = localProduct;
            upProduct.brands = selectedBrands;
            setLocalProduct(upProduct);
            const snackbarData: SnackbarOptions = {
                message: 'Brands salvati correttamente',
                type: 'success',
            };
            dispatch(addSnackbar(snackbarData));

            if ((product.type === 'THEMATIC_BROADCAST') && (Object.keys(originalRoadmapEvent).length > 0)) {
                setTimeout(() => {
                    sendToRoadmap();
                }, 2000);
            }
        } catch (error: any) {
            const snackbarData: SnackbarOptions = {
                message: 'Non hai i permessi per salvare questi brand',
                type: 'error',
            };
            dispatch(addSnackbar(snackbarData));
        } finally {
            setIsSaving(false);
            setIsModified(false);
        }
    };

    useEffect(() => {
        const getBrands = async () => {
            const brands = await getBrandsList();
            console.log(brands);
            setBrands(brands);
        };
        getBrands();
    }, []);

    useEffect(() => {
        if (localProduct.brands.length > 0) {
            setSelectedBrands(localProduct.brands);
        }
    }, [localProduct.brands]);

    useEffect(() => {
        const productBrandCodes = new Set(localProduct.brands.map((b: Brand) => b.code));
        const selectedBrandCodes = new Set(selectedBrands.map((b: Brand) => b.code));

        const hasDifferences = productBrandCodes.size !== selectedBrandCodes.size ||
            Array.from(productBrandCodes).some(code => typeof code === 'string' && !selectedBrandCodes.has(code));

        setIsModified(hasDifferences);
    }, [selectedBrands, product.brands]);

    useEffect(() => {
        console.log(selectedBrands);
    }, [selectedBrands]);

    useEffect(() => {
		if (!currentBrand) return;
		if (!product) return;
		const gre = async () => {
			const response = await getRoadmapEvents(currentBrand);
			// console.log("2ROADMAP: looking for /"+product.id.toString());
			const targetEvent = response.find((e: any) => {
				// console.log("Checking event: ", e);
				if (e.link && e.link.includes(product?.productThematicCall?.type === 'ESPERIENZA' ? 'esperienza' : 'product-diretta-tematica') && e.link.endsWith("/"+product?.id.toString())) {
					return e;
				}
			})
			// console.log("2ROADMAP: target event: ", targetEvent);
			if (targetEvent) {
				// console.log("2ROADMAP: found event: ", targetEvent.id);
				setOriginalRoadmapEvent(targetEvent);
			} else { 
				//console.log("2ROADMAP: not found event"); 
			}
			
		}
		const gret = async () => {
			const response = await getRoadmapEventTypes(currentBrand);
			if (response) { setOriginalRoadmapTypesList(response); }
		}
		gre();
		gret();
	}, [currentBrand, product, selectedBrands]);

	return (
		<MainBox>
			{brands.map((brand) => { if (brand.code !== "VDR") {
				return (
				<BrandItem key={brand.id}>
						<BrandCheckbox key={brand.code} disabled={brand.code === currentBrand?.code || isReadOnly} checked={brand.code === currentBrand?.code || selectedBrands.map((b) => b.code).includes(brand.code)} onChange={() => handleBrandChange(brand)}/>
						{brand.name}
				</BrandItem>
			)}})}
			<ButtonSave onClick={() => handleSave()} disabled={selectedBrands.length === 0 || isSaving || !isModified || isReadOnly}>SALVA BRAND</ButtonSave>
		</MainBox>
	);
};

export default Multibrand;
