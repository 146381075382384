import { FC, useEffect, useState } from 'react';

//components
import BaseModal from 'components/ui/BaseModal/BaseModal.component';

//types
import { PaymentMethodsProps } from './PaymentMethods.component.d';
import { PaypalServicePaymentResponse } from 'types/PaypalServicePaymentResponse';
import { PaypalSinglePaymentResponse } from 'types/PaypalSinglePaymentResponse';

//utils
import { getSinglePurchasePaypalDetails, getServicePurchasePaypalDetails } from '../../services/PaymentsPage.services';

//assets
import { ReactComponent as PaypalLogo } from 'assets/imgs/paymentMethods/paypalLogo.svg';
import { ReactComponent as LibertyPointsLogo } from 'assets/imgs/paymentMethods/libertyPointsLogo.svg';
import { ReactComponent as BankTransferLogo } from 'assets/imgs/paymentMethods/bankTransferLogo.svg';

//style
import { PaymentMethodsBox, CardContent } from './style/PaymentMethods.component.style';

const PaymentMethods: FC<PaymentMethodsProps> = ({ purchase }) => {
    const [methods, setMethods] = useState<string[]>([]);
    const [purchaseId, setPurchaseId] = useState<number>(-1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState(false);

    const openModal = (message: string) => {
        setModalMessage(message);
        setIsCloseButtonClicked(false);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsCloseButtonClicked(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setModalMessage('');
            setIsCloseButtonClicked(false);
        }, 100);
    };

    const paypalDetailClickHandler = async () => {
        if (purchase.status === 'CREATED' || purchase.status === 'CANCELED') {
            openModal(`Impossibile avere il dettaglio Paypal per un pagamento in stato ${purchase.status}`);
            return;
        }

        if (purchase.product.type === 'SERVICE') {
            try {
                const response: PaypalServicePaymentResponse = await getServicePurchasePaypalDetails(purchaseId);
                const info = purchase.transactions?.reduce((prev, curr) => {
                    if (curr.status === 'COMPLETED')
                        return (prev += `${curr.paymentType.name}: ${
                            curr.paymentType.name.includes('Punti')
                                ? (curr.netAmount + curr.fees).toLocaleString()
                                : curr.gross.toLocaleString()
                        }\n`);
                    return prev;
                }, '');
                let message = `${info}\n\nNome: ${response?.subscriber?.name?.given_name} ${response?.subscriber?.name?.surname}\nEmail: ${response?.subscriber?.email_address}\nPaypalRecurringPaymentId: ${response?.id} \n\nPaypalTxs: \n\n`;
                response.transactionList?.transactions
                    ?.sort((a, b) => {
                        if (a.time < b.time) return 1;
                        if (a.time > b.time) return -1;
                        return 0;
                    })
                    .forEach(
                        (t) =>
                            (message += `${t.id} di ${Number(t.amount_with_breakdown?.gross_amount?.value)?.toLocaleString()}€ del ${new Date(
                                t.time
                            ).toLocaleString()}\n\n`)
                    );
                openModal(message);
            } catch (error) {
                openModal('Errore nel cercare il dettaglio Paypal');
            }
        } else {
            try {
                const response: PaypalSinglePaymentResponse = await getSinglePurchasePaypalDetails(purchaseId);
                const info = purchase.transactions?.reduce((prev, curr) => {
                    if (curr.status === 'COMPLETED')
                        return (prev += `${curr.paymentType.name}: ${
                            curr.paymentType.name.includes('Punti')
                                ? (curr.netAmount + curr.fees).toLocaleString()
                                : curr.gross.toLocaleString()
                        }\n`);
                    return prev;
                }, '');
                openModal(
                    `${info}\n\nNome: ${response?.payer?.name?.given_name} ${response?.payer?.name?.surname}\nEmail: ${response?.payer?.email_address}\nPaypalTxId: ${
                        response?.purchase_units[0]?.payments.captures[0]?.id
                    } di ${Number(response?.purchase_units[0]?.amount?.value)?.toLocaleString()}€ del ${new Date(
                        response?.purchase_units[0]?.payments.captures[0]?.create_time
                    ).toLocaleString()}`
                );
            } catch (error) {
                openModal('Errore nel cercare il dettaglio Paypal');
            }
        }
    };

    const paymentMethodMapper = (method: string) => {
        if (method.includes('Paypal'))
            return (
                <PaypalLogo
                    title='Click per dettaglio Paypal'
                    style={{ cursor: 'pointer' }}
                    onClick={paypalDetailClickHandler}
                />
            );
        if (method.includes('Punti')) return <LibertyPointsLogo />;
        if (method.includes('Bonifico')) return <BankTransferLogo />;
        return <></>;
    };

    useEffect(() => {
        setMethods(Array.from(new Set(purchase.transactions?.map((t) => t.paymentType.name))).sort());
        setPurchaseId(purchase.transactions[0]?.purchaseId);
    }, [purchase]);

    return (
        <>
            <PaymentMethodsBox>{methods.map(paymentMethodMapper)}</PaymentMethodsBox>
            {isModalOpen && (
                <BaseModal
                    onClose={closeModal}
                    isCloseButtonClicked={isCloseButtonClicked}
                    isBackdropClickActive={true}
                >
                    <div style={{ padding: '20px', whiteSpace: 'pre-line' }}>
                        <CardContent>
                        <p>{modalMessage}</p>
                        <button
                            onClick={closeModal}
                            style={{
                                marginTop: '20px',
                                padding: '8px 16px',
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                        >
                            Chiudi
                        </button>
                        </CardContent>
                    </div>
                </BaseModal>
            )}
        </>
    );
};

export default PaymentMethods;

