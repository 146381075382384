import styled from 'styled-components';
import colors from 'style/palette/palette';

export const PaymentMethodsBox = styled.div`
	display: flex;
	gap: 0.5rem;
`;

export const CardContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
    gap: 30px;

    width: 1000px;
    max-height: 800px;

    margin: 0;
    padding: 30px;

    border: 1px solid ${colors.primaryColor};
    border-radius: 15px;

    background: ${colors.grey};
    color: ${colors.white};

    overflow: auto;

    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    div {
        width: 100%;
    }
`