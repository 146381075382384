import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//assets
import { ReactComponent as IsPinnedIconSVG} from './assets/isPinnedIcon.svg';
import { ReactComponent as IsPinnedIconSVGDisabled} from './assets/isPinnedOffIcon.svg';

//types
import { IsPinnedProps } from './IsPinned.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

//style
import { IsPinnedBox, IsPinnedIcon, PriceType, PriceTypeSpec } from './style/IsPinned.component.style';
import useHasRole from 'hooks/useHasRole';

const IsPinned: FC<IsPinnedProps> = ({ product, putProduct, isReadOnly }) => {
	const dispatch = useAppDispatch();
	const isMounted = useIsMounted();

	const isAdmin = useHasRole('ADMIN');
	const isSuperAdmin = useHasRole('SUPERADMIN');
	const isMiniAdmin = useHasRole('MINIADMIN');

	const clickHandler = async () => {
		if (!product.id) return;
		if (isReadOnly) {
			alert('Non hai i permessi per modificare la messa in evidenza del prodotto');
			return;
		}
		const snackbarData: SnackbarOptions = {};

		try {
			await putProduct({ id: product.id, pinned: !product.pinned });
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nell'aggiornamento messa in evidenza";
			dispatch(addSnackbar(snackbarData));
			console.log(error);
			if (!isMounted()) return;
		}
	};

	return (
		<IsPinnedBox>
			<IsPinnedIcon isPinned={product.pinned} canToggle={true || !isReadOnly} onClick={clickHandler } title={"clicca per "+(product.pinned ? "togliere HOT" : "rendere HOT")}>{product.pinned ? <IsPinnedIconSVG /> : <IsPinnedIconSVGDisabled />}</IsPinnedIcon>
		</IsPinnedBox>
	);
};

export default IsPinned;
