import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { getEnvironmentTestBaseUrl } from 'configs/config.helper';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useBrand from 'hooks/useBrand';

//types
import { SubscriptionLimitProps } from './SubscriptionLimit.component.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { RoadmapEvent } from 'types/RoadmapEvent';
import { RoadmapEventType } from 'types/RoadmapEventType';
import { Brand } from 'types/Brand';
import { Product } from 'types/Product';

//utils
import { 
	removeSubscriptionNumberLimit,
	removeSubscriptionDateLimit,
	removeThematicBroadcastProtagonistNumberLimit,
	removeThematicBroadcastSpectatorNumberLimit,
	removeThematicBroadcastExpirationDateLimit,
	removeThematicBroadcastLiveDateLimit,
	removeThematicBroadcastSaleCollectionDateLimit,
	scheduleThematicBroadcast
 } from './services/SubscriptionLimit.services';
import { putRoadmapEvent } from 'services/roadmapevent.services';
import { getRoadmapEvents, getRoadmapEventTypes, addEvent } from '../../../RoadmapPage/services/RoadmapPage.services';

//styles
import {
	SubscriptionLabel,
	SubscriptionLimitBox,
	SubscriptionMaxNumber,
	SaveButton,
	RemoveButton,
	UnlockButton,
	SubscriptionCurrentNumber,
	SubscriptionMaxDate,
	HoursDiv
} from './style/SubscriptionLimit.component.style';
import { Row, Col } from 'style/layout';
import ThematicBroadcastDetail from 'pages/ProductDetailPage/ThematicBroadcastDetail/ThematicBroadcastDetail.component';
import { removeThematicBroadcastDiscountPrices } from 'pages/ProductDetailPage/PercentagesTab/services/PercentagesTab.services';

const SubscriptionLimit: FC<SubscriptionLimitProps> = ({ product, putProduct, isReadOnly }) => {
	const [isModified, setIsModified] = useState<boolean>(false);
	const [isPublishing, setIsPublishing] = useState<boolean>(false);
	const [isGoingToRoadmap, setIsGoingToRoadmap] = useState<boolean>(false);
	const [canEditMaxProtagonist, setCanEditMaxProtagonist] = useState<boolean>(false);
	const [canEditMaxSpectator, setCanEditMaxSpectator] = useState<boolean>(false);
	const [canEditLive, setCanEditLive] = useState<boolean>(false);
	const [localMaxSubscriptionNumber, setLocalMaxSubscriptionNumber] = useState(0);
	const [localMaxSubscriptionDate, setLocalMaxSubscriptionDate] = useState<Date>();

	const [localMaxProtagonistNumber, setLocalMaxProtagonistNumber] = useState(0);
	const [localMaxSpectatorNumber, setLocalMaxSpectatorNumber] = useState(0);

	const [localLiveDate, setLocalLiveDate] = useState<Date>();
	const [localLiveHour, setLocalLiveHour] = useState(-1);
	const [localLiveMinute, setLocalLiveMinute] = useState(-1);
	const [localAbsoluteLiveDate, setLocalAbsoluteLiveDate] = useState('');

	const [localExpirationDate, setLocalExpirationDate] = useState<Date>();
	const [localExpirationHour, setLocalExpirationHour] = useState(-1);
	const [localExpirationMinute, setLocalExpirationMinute] = useState(-1);
	const [localAbsoluteExpirationDate, setLocalAbsoluteExpirationDate] = useState('');

	const [originalRoadmapEvent, setOriginalRoadmapEvent] = useState<RoadmapEvent>({} as RoadmapEvent);
	const [originalRoadmapTypesList, setOriginalRoadmapTypesList] = useState<RoadmapEventType[]>();
	
	const brand = useBrand();

	const dispatch = useAppDispatch();

	const isValidDate = (dateToCheck: Date | string): boolean => {
		if (!dateToCheck) return false;
		try {
			const testDate = new Date(dateToCheck);
			return true;
		} catch (err) {
			return false;
		}
	};

	const convertDate = (dateToConvert: string): string => {
		const [parcialDate, parcialTime] = dateToConvert.split(", ");
		const [dday, dmonth, dyear] = parcialDate.split("/");
		const [dhours, dminutes, desconds] = parcialTime.split(":");
		const convertedDate = `${dyear}-${dmonth.padStart(2, "0")}-${dday.padStart(2, "0")}T${dhours.padStart(2, "0")}:${dminutes}:${desconds}`;
		return convertedDate;
	}

	const handleCheckboxProtagonistChange = () => {
		setCanEditMaxProtagonist(!canEditMaxProtagonist);
		setIsModified(true);
	}

	const handleCheckboxSpectatorChange = () => {
		setCanEditMaxSpectator(!canEditMaxSpectator);
		setIsModified(true);
	}

	const onMaxSubscriptionNumberChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalMaxSubscriptionNumber(+event.currentTarget.value);
		setIsModified(true);
	};

	const onMaxProtagonistNumberChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalMaxProtagonistNumber(+event.currentTarget.value);
		setIsModified(true);
	};

	const onMaxSpectatorNumberChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalMaxSpectatorNumber(+event.currentTarget.value);
		setIsModified(true);
	};

	const onMaxSubscriptionDateChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!isValidDate(event.currentTarget.value)) return;
		const localTime = new Date();
		const hoursOffset = localTime.getTimezoneOffset() / 60;
		setLocalMaxSubscriptionDate(new Date(event.currentTarget.value.split('T')[0]+'T'+(23+hoursOffset)+':59:59'));
		setIsModified(true);
	};

	const onLiveDateChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => { 
		if (!isValidDate(event.currentTarget.value)) return;
		var subDate = new Date(event.currentTarget.value);
		var year = subDate.getFullYear().toString();
		const month = (subDate.getMonth() + 1).toString().padStart(2, '0'); 
		const day = subDate.getDate().toString().padStart(2, '0');
		const isoDate = `${year.padEnd(4,'0')}-${month}-${day}`;
		console.log('isoDate:',isoDate);
		console.log('subDate:',subDate);
		setLocalLiveDate(subDate);
		setLocalExpirationDate(subDate);
		setLocalAbsoluteLiveDate(isoDate);
		setLocalAbsoluteExpirationDate(isoDate);
		setIsModified(true);
	};

	const onLiveHourChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => { 
		setLocalLiveHour(Number(event.currentTarget.value));
		setLocalExpirationHour(Number(event.currentTarget.value));

		if ((!localLiveDate) || (!isValidDate(localLiveDate))) return;
		const subDate = new Date(localLiveDate);
		subDate.setHours(Number(event.currentTarget.value));
		subDate.setMinutes(localLiveMinute);
		subDate.setSeconds(0);
		setIsModified(true);
		// setLocalAbsoluteLiveDate(subDate.toISOString());
	};

	const onHourExpirationChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => { 
		setLocalExpirationHour(Number(event.currentTarget.value));
		if ((!localExpirationDate) || (!isValidDate(localExpirationDate))) return;
		const subDate = new Date(localExpirationDate);
		subDate.setHours(Number(event.currentTarget.value));
		subDate.setMinutes(localExpirationMinute);
		subDate.setSeconds(0);
		setIsModified(true);
		// setLocalAbsoluteExpirationDate(subDate.toISOString());
	};

	const onLiveMinuteChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => { 
		setLocalLiveMinute(Number(event.currentTarget.value));
		setLocalExpirationMinute(Number(event.currentTarget.value));

		if ((!localLiveDate) || (!isValidDate(localLiveDate))) return;
		const subDate = new Date(localLiveDate);
		subDate.setHours(localLiveHour);
		if (Number(event.currentTarget.value) >= 0) subDate.setMinutes(Number(event.currentTarget.value));
		subDate.setSeconds(0);
		if (Number(event.currentTarget.value) < 0) subDate.setSeconds(1);
		// setLocalAbsoluteLiveDate(subDate.toISOString());
		setIsModified(true);
	};

	const onMinuteExpirationChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => { 
		setLocalExpirationMinute(Number(event.currentTarget.value));
		if ((!localExpirationDate) || (!isValidDate(localExpirationDate))) return;
		const subDate = new Date(localExpirationDate);
		subDate.setHours(localExpirationHour);
		if (Number(event.currentTarget.value) >= 0) subDate.setMinutes(Number(event.currentTarget.value));
		subDate.setSeconds(0);
		if (Number(event.currentTarget.value) < 0) subDate.setSeconds(1);
		// setLocalAbsoluteExpirationDate(subDate.toISOString());
		setIsModified(true);
	};

	const onExpirationDateChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("localExiprationDate ONCHANGE:",event.currentTarget.value);
		if (!isValidDate(event.currentTarget.value)) return;
		const localTime = new Date();
		console.log('localTime:',localTime);
		const hoursOffset = localTime.getTimezoneOffset() / 60;
		console.log('hoursOffset:',hoursOffset);
		if (product.productThematicCall.type !== 'ESPERIENZA') {
			setLocalExpirationDate(new Date(event.currentTarget.value.split('T')[0]+'T'+(23+hoursOffset)+':59:59'));
			console.log('localExpirationDate SET:',new Date(event.currentTarget.value.split('T')[0]+'T'+(23+hoursOffset)+':59:59'));
		} else {
			setLocalExpirationDate(new Date(event.currentTarget.value.split('T')[0] + 'T' + localExpirationHour.toString().padStart(2, '0') + ':' + localExpirationMinute.toString().padStart(2, '0') + ':00'));
			console.log('localExpirationDate SET:',new Date(event.currentTarget.value.split('T')[0]+'T'+(localExpirationHour+hoursOffset)+':'+localExpirationMinute+':00'));
		}
		setIsModified(true);
	};

	const publishHandler = async () => {
		setIsPublishing(true);
	}

	const goToRoadmapHandler = async () => {
		setIsGoingToRoadmap(true);
	}

	const saveHandler = async () => {
		const snackbarData: SnackbarOptions = {};

		if ((product.type === 'THEMATIC_BROADCAST') && (canEditMaxProtagonist === false) && (canEditMaxSpectator === false)) {
			snackbarData.type = 'error';
			snackbarData.message = "Devi attivare almeno una tipologia di pubblico tra Protagonisti e Spettatori";
			dispatch(addSnackbar(snackbarData));
			return;
		}

		if ((product.type === 'THEMATIC_BROADCAST') && (localAbsoluteLiveDate === '')) {
			snackbarData.type = 'error';
			snackbarData.message = "Devi specificare la data della "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula");
			dispatch(addSnackbar(snackbarData));
			return;
		}

		if ((product.type === 'THEMATIC_BROADCAST') && ((localLiveHour < 0) || (localLiveMinute < 0))) {
			snackbarData.type = 'error';
			snackbarData.message = "Devi specificare l'orario della "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula");
			dispatch(addSnackbar(snackbarData));
			return;
		}

		if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.status === 'SCHEDULED')) {
			// eslint-disable-next-line no-restricted-globals
			if (!confirm("Vuoi veramente SALVARE le MODIFICHE alla DATA della "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula")+" che si terrà quindi il giorno "+localAbsoluteLiveDate+" alle ore "+localLiveHour+":"+localLiveMinute+" ??? In tal caso NON verranno inviate nuove NOTIFICHE agli utenti fino a quelle previste nelle ultime 72 ore prima dell'evento.")) {
				return;
			}
		}

		var updatedProduct = { id: product.id } as Partial<Product>;
		if (product.type === 'THEMATIC_BROADCAST') updatedProduct.productThematicCall = { ...product.productThematicCall};

		if ((product.type !== 'THEMATIC_BROADCAST') && (localMaxSubscriptionNumber === 0) && (product.maxSubscription > 0)) await removeSubscriptionNumberLimit(product.id);
		if ((product.type !== 'THEMATIC_BROADCAST') && (localMaxSubscriptionNumber)) updatedProduct.maxSubscription = localMaxSubscriptionNumber;
		
		if (localMaxSubscriptionDate) {
			var subDate = localMaxSubscriptionDate;
			subDate.setHours(23);
			subDate.setMinutes(59);
			subDate.setSeconds(59);
			if (product.type !== 'THEMATIC_BROADCAST') updatedProduct.subscriptionMaxDate = subDate.toISOString();
			if ((product.type === 'THEMATIC_BROADCAST') && (updatedProduct.productThematicCall)) {
				updatedProduct.productThematicCall.saleCollectionDate = subDate.toISOString();
			}
		}

		if ((product.type === 'THEMATIC_BROADCAST') && (updatedProduct.productThematicCall)) {
			if (canEditMaxProtagonist) {
				if (localMaxProtagonistNumber === 0) {
					const response = await removeThematicBroadcastProtagonistNumberLimit(product.id);
					updatedProduct.productThematicCall.maxProtagonist = null;
				} else {
					updatedProduct.productThematicCall.maxProtagonist = localMaxProtagonistNumber;
				}
			} else {
				updatedProduct.productThematicCall.maxProtagonist = 0;
			}

			if ((product.type === 'THEMATIC_BROADCAST') && (canEditMaxSpectator)) {
				if (localMaxSpectatorNumber === 0) {
					const response = await removeThematicBroadcastSpectatorNumberLimit(product.id);
					updatedProduct.productThematicCall.maxSpectator = null;
				} else {
					updatedProduct.productThematicCall.maxSpectator = localMaxSpectatorNumber;
				}
			} else {
				// if (product?.productThematicCall?.type === 'ESPERIENZA') {
					updatedProduct.productThematicCall.maxSpectator = 0;
				// }
			}
		
			if ((product.type === 'THEMATIC_BROADCAST') && (localLiveDate)) {
				subDate = localLiveDate;
				subDate.setHours(localLiveHour);
				subDate.setMinutes(localLiveMinute);
				subDate.setSeconds(0);
				updatedProduct.productThematicCall.liveDate = subDate.toISOString();
				// console.log('UPDATE product.productThematicCall.liveDate:',product.productThematicCall.liveDate);
				// console.log('UPDATE subDate.toISOString():',subDate.toISOString());
				// console.log('UPDATE product.productThematicCall.liveDate.toString():',product.productThematicCall.liveDate.toString());
				// console.log('UPDATE subDate.toISOString().toString():',subDate.toISOString().toString());
				// console.log('new Date(product.productThematicCall.liveDate).getTime():',new Date(product.productThematicCall.liveDate).getTime());
				// console.log('new Date(subDate.toISOString()).getTime():',new Date(subDate.toISOString()).getTime());
				let liveDateStr = "";
				if (product.productThematicCall.liveDate) {
					liveDateStr = product.productThematicCall.liveDate.toString();
				}
				if (!liveDateStr.endsWith('.000Z')) {
					liveDateStr += '.000Z';
				  }
				if (liveDateStr !== subDate.toISOString().toString()) {
					// console.log("UPDATE updatedProduct.productThematicCall.status PRE:",updatedProduct.productThematicCall.status);
					updatedProduct.productThematicCall.status = 'CREATED';
					// console.log("UPDATE updatedProduct.productThematicCall.status POST:",updatedProduct.productThematicCall.status);
				}
			}

			if ((product.type === 'THEMATIC_BROADCAST') && (localExpirationDate)) {
				subDate = localExpirationDate;
				
				if (product.productThematicCall.type === 'ESPERIENZA') {
					subDate.setHours(localExpirationHour);
					subDate.setMinutes(localExpirationMinute);
					subDate.setSeconds(0);
					updatedProduct.subscriptionMaxDate = subDate.toISOString();
				} else {
					subDate.setHours(23);
					subDate.setMinutes(59);
					subDate.setSeconds(59);
					updatedProduct.productThematicCall.expirationDate = subDate.toISOString();
				}
			}

		}
		if ((product.type === 'THEMATIC_BROADCAST') && updatedProduct.productThematicCall && Object.keys(updatedProduct.productThematicCall).length === 0) {
			const { productThematicCall, ...rest } = updatedProduct;
			updatedProduct = rest;
		}
		if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA')) {
			if (updatedProduct.productThematicCall) {
				updatedProduct.productThematicCall.maxSpectator = 0;
				// const response = await removeThematicBroadcastSpectatorNumberLimit(product.id);
			}
		}

		try {
			setTimeout(() => {
				putProduct(updatedProduct);
			}, 1000);
			snackbarData.type = 'success';
			snackbarData.message = "Modifiche salvate con successo";
			dispatch(addSnackbar(snackbarData));
			if ((product.type === 'THEMATIC_BROADCAST') && (Object.keys(originalRoadmapEvent).length > 0)) {
				setTimeout(() => {
					sendToRoadmap();
				}, 2000);
			}
			setIsModified(false);
		} catch (error) {
			snackbarData.type = 'error';
			snackbarData.message = "Errore nel salvataggio delle modifiche";
			dispatch(addSnackbar(snackbarData));
			console.log("2PRODUCT: error:",error);
		}
	};

	const sendToRoadmap = async () => {
		try {
			console.log("2ROADMAP: sendToRoadmap");
			if (!brand) {
				throw new Error('brand not defined');
				console.log("2ROADMAP: brand not defined");
			}
			if (!originalRoadmapTypesList) {
				throw new Error('originalRoadmapTypesList not defined');
				console.log("2ROADMAP: originalRoadmapTypesList not defined");
			}
			const link = `${getEnvironmentTestBaseUrl(
			product?.productThematicCall.type === 'ESPERIENZA' ? 
			`app/public/product-esperienza/${product.id}`.replace('backoffice.', '') :
			`app/reservedArea/product-diretta-tematica/${product.id}`).replace('backoffice.', '')}`;
			console.log("2ROADMAP: link:",link);
			let roadmapEvent = {} as Partial<RoadmapEvent>;
			roadmapEvent.name = product.name;
			let subDate;
			if (product.productThematicCall.type === 'ESPERIENZA') {
				subDate = localExpirationDate;
				subDate?.setHours(localExpirationHour);
				subDate?.setMinutes(localExpirationMinute);
				subDate?.setSeconds(0);
			} else {
				subDate = localLiveDate;
				subDate?.setHours(localLiveHour);
				subDate?.setMinutes(localLiveMinute);
				subDate?.setSeconds(0);
			}
			const roadmapDate = subDate?.toISOString();
			roadmapEvent.beginDate = roadmapDate;
			console.log("PRE: ",roadmapEvent);
			console.log("2ROADMAP: roadmapEvent:",roadmapEvent);
			const snackbarData: SnackbarOptions = {};
			if ((Object.keys(originalRoadmapEvent).length > 0) && originalRoadmapEvent.id) {
				console.log("2ROADMAP UPD: ",roadmapEvent);
				roadmapEvent.id = originalRoadmapEvent.id;
				roadmapEvent.brands = product.brands.map((brand: Brand) => ({ id: brand.id, code: brand.code })) as Brand[];
				await putRoadmapEvent(roadmapEvent, brand.code);
				snackbarData.message = "Evento roadmap aggiornato con successo";	
			} else {
				console.log("2ROADMAP NEW: ",roadmapEvent);
				roadmapEvent.visible = true;
				const newType: RoadmapEventType | undefined = originalRoadmapTypesList.find((ty) =>  ty.name.toLowerCase() === (product.productThematicCall.type === 'ESPERIENZA' ? 'esperienza' : 'aula'));
				if (!newType) throw new Error('newType not defined');
				roadmapEvent.eventType = { id: newType.id } as RoadmapEventType;
				roadmapEvent.link = link;
				const newEvent = await addEvent(roadmapEvent, brand.code);
				setOriginalRoadmapEvent(newEvent);
				var brandRoadmapEvent = { id: newEvent.id } as Partial<RoadmapEvent>;
				brandRoadmapEvent.brands = product.brands.map((brand: Brand) => ({ id: brand.id, code: brand.code })) as Brand[];
				await putRoadmapEvent(brandRoadmapEvent, newEvent.brands[0].code);
				snackbarData.message = "Evento roadmap aggiunto con successo";
			}
			snackbarData.type = 'success';
			dispatch(addSnackbar(snackbarData));
		} catch (error) {
			const snackbarData: SnackbarOptions = {};
			snackbarData.type = 'error';
			snackbarData.message = "C'è stato un problema nel mandare su roadmap la "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula");
			dispatch(addSnackbar(snackbarData));
		}
	}

	const checkThematicBroadcastForCreated = () => {
		if (product.type !== 'THEMATIC_BROADCAST') return false;
		if (product.productThematicCall.status === 'CREATED') return true;
	}

	const checkThematicBroadcastForScheduled = () => {
		if (product.type !== 'THEMATIC_BROADCAST') return false;
		if (product.productThematicCall.status === 'SCHEDULED') return true;
	}

	const checkThematicBroadcastForPublished = () => {
		if (product.type !== 'THEMATIC_BROADCAST') return false;
		if (product.productThematicCall.status === 'PUBLISHED') return true;
	}

	const handleRemoveSubscriptionBtn = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente rimuovere il limite di POSTI ???')) {
			await removeSubscriptionNumberLimit(product.id);
			product.maxSubscription = 0;
			setLocalMaxSubscriptionNumber(0);
		}
	}

	const handleRemoveProtagonistBtn = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente rimuovere il limite di PROTAGONISTI ???')) {
			const response = await removeThematicBroadcastProtagonistNumberLimit(product.id);
			if (response === '') {
				product.productThematicCall.maxProtagonist = 0;
				setLocalMaxProtagonistNumber(0);
				setIsModified(true);
			}
		}
	}

	const handleRemoveSpectatorBtn = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente rimuovere il limite di SPETTATORI ???')) {
			const response = await removeThematicBroadcastSpectatorNumberLimit(product.id);
			if (response === '') {
				product.productThematicCall.maxSpectator = 0;
				setLocalMaxSpectatorNumber(0);
				setIsModified(true);
			}
		}
	}

	const handleRemoveMaxDateBtn = async () => {
		var question = "Vuoi veramente rimuovere la DATA limite per acquistare ???";
		if (product?.type === 'THEMATIC_BROADCAST') {
			question = "Vuoi veramente rimuovere la DATA limite per iscriversi ???";
			if ((product?.productThematicCall?.type === 'ESPERIENZA')) {
				question = "Vuoi veramente rimuovere la DATA fine esperienza ???";
			}
		}
		// eslint-disable-next-line no-restricted-globals
		if (confirm(question)) {
			await removeSubscriptionDateLimit(product.id);
			product.subscriptionMaxDate = '';
			setLocalMaxSubscriptionDate(undefined);
			setIsModified(true);
		}
	}

	const handleRemoveLiveDateBtn = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente rimuovere la DATA della '+((product.productThematicCall.type === 'ESPERIENZA') ? 'esperienza' : 'aula')+' ???')) {
			await removeThematicBroadcastLiveDateLimit(product.id);
			product.productThematicCall.liveDate = '';
			setLocalLiveDate(undefined);
			setLocalAbsoluteLiveDate('');
			setIsModified(true);
		}
	}

	const handleUnlockLiveDataBtn = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente SBLOCCARE la DATA della '+((product.productThematicCall.type === 'ESPERIENZA') ? 'esperienza' : 'aula')+' ???')) {
			setCanEditLive(true);
		}
	}

	const handleRemoveExpirationDateBtn = async () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Vuoi veramente rimuovere la DATA limite di visibilità della '+((product.productThematicCall.type === 'ESPERIENZA') ? 'esperienza' : 'aula')+' ???')) {
			await removeThematicBroadcastExpirationDateLimit(product.id);
			product.productThematicCall.expirationDate = '';
			setLocalExpirationDate(undefined);
			setIsModified(true);
		}
	}

	useEffect(() => {
		const publish = async () => {
			if (!isPublishing) return;
			// eslint-disable-next-line no-restricted-globals
			if (!confirm('Vuoi veramente PUBBLICARE la ' +((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula") + ' per il giorno '+localAbsoluteLiveDate?.toLocaleString()+' alle '+('00'+localLiveHour).slice(-2)+':'+('00'+localLiveMinute).slice(-2)+' (tuo orario locale)???')) {
				setIsPublishing(false);
				return;
			}
			try {
				
				const response = await scheduleThematicBroadcast(product.id);
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "C'è stato un problema nel pubblicare la "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula");
				dispatch(addSnackbar(snackbarData));
				setIsPublishing(false);
				return;
			}
			var updatedProduct = { id: product.id } as Partial<Product>;
			updatedProduct.productThematicCall = { ...product.productThematicCall, status: 'SCHEDULED'};
			if (product.productThematicCall.type === 'ESPERIENZA') {
				updatedProduct.productThematicCall.price = null;
				updatedProduct.productThematicCall.spectatorPrice = null;
				updatedProduct.productThematicCall.discountExpirationDate = null;
				updatedProduct.productThematicCall.maxSpectator = 0;
				// const response = await removeThematicBroadcastSpectatorNumberLimit(product.id);
			}
				updatedProduct.productThematicCall.discountProtagonistPrice = null;
				updatedProduct.productThematicCall.discountSpectatorPrice = null;
			try {
				const response = await removeThematicBroadcastDiscountPrices(product.id);
			} catch (error) {
				const snackbarData: SnackbarOptions = {};
				snackbarData.type = 'error';
				snackbarData.message = "C'è stato un problema nel pubblicare la "+((product.productThematicCall.type === 'ESPERIENZA') ? "esperienza" : "aula");
				dispatch(addSnackbar(snackbarData));
				setIsPublishing(false);
				return;
			}
			//ritarda di un secondo l'esecuzione del prossimo comando per evitare problemi di sincronizzazione
			setTimeout(() => {
				putProduct(updatedProduct);
				setIsPublishing(false);
			}, 1500);
			if (product.productThematicCall.type === 'ESPERIENZA') {
				setTimeout(() => {
					sendToRoadmap();
				}, 2000);
			}
			// await putProduct(updatedProduct);
		}
		publish();
	}, [isPublishing]);

	useEffect(() => {
		console.log("2ROADMAP: isGoingToRoadmap: "+isGoingToRoadmap);
		if (isGoingToRoadmap === false) return;
		const S2R = async () => {
			try {
				await sendToRoadmap();
			} catch (error) {				
			}
			setIsGoingToRoadmap(false);
		}
		S2R();
	}, [isGoingToRoadmap]);

	useEffect(() => {
		if ((product.type !== 'THEMATIC_BROADCAST') && (product.maxSubscription)) setLocalMaxSubscriptionNumber(product.maxSubscription);
	}, [product.maxSubscription]);

	useEffect(() => {
		if (product.type === 'THEMATIC_BROADCAST' && product.productThematicCall.maxProtagonist !== undefined) {
		  if (product.productThematicCall.maxProtagonist !== null) { 
			if (product.productThematicCall.maxProtagonist > 0) {
			  setCanEditMaxProtagonist(true);
			  setLocalMaxProtagonistNumber(product.productThematicCall.maxProtagonist);
			} else {
			  setCanEditMaxProtagonist(false);
			}
		  } else {
			setCanEditMaxProtagonist(true);
			setLocalMaxProtagonistNumber(0);
		  }
		}
	  }, [product.type, product?.productThematicCall?.maxProtagonist]);


	// useEffect(() => {
	// 	if (product.type !== 'THEMATIC_BROADCAST') return;
	// 	if (product.productThematicCall.maxSpectator === undefined) return;
	// 	if (product.productThematicCall.maxSpectator !== null) {
	// 		if (product.productThematicCall.maxSpectator > 0) { // specific spectators number
	// 			setCanEditMaxSpectator(true);
	// 			setLocalMaxSpectatorNumber(product.productThematicCall.maxSpectator);
	// 		} else { // no spectators allowed
	// 			setCanEditMaxSpectator(false);
	// 		}
	// 	} else { // unlimited spectators
	// 		setCanEditMaxSpectator(true);
	// 		setLocalMaxSpectatorNumber(0);
	// 	}	
	// }, [product.type, product?.productThematicCall?.maxSpectator]);

	useEffect(() => {
		if ((product.type !== 'THEMATIC_BROADCAST') && (product.subscriptionMaxDate)) {
			setLocalMaxSubscriptionDate(new Date(product.subscriptionMaxDate));
		} else {
			if (product?.productThematicCall?.type === 'ESPERIENZA') {
				if (product.subscriptionMaxDate) {
						const fixedDate = ((typeof product.subscriptionMaxDate === "string") && (product.subscriptionMaxDate.slice(-1) === 'Z')) ? product.subscriptionMaxDate : product.subscriptionMaxDate+'Z';
						const utcDate = new Date(fixedDate);
						const year = utcDate.getFullYear();
						const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
						const day = utcDate.getDate().toString().padStart(2, '0');
						const isoDate = `${year}-${month}-${day}`;
						const onlyDate = new Date(isoDate);
			
						if (!isValidDate(utcDate)) return;
						const localDatetimeString = convertDate(utcDate.toLocaleString());
						setLocalExpirationDate(new Date(onlyDate));
						setLocalAbsoluteExpirationDate(isoDate);
						setLocalExpirationHour((new Date(localDatetimeString)).getHours());
						setLocalExpirationMinute((new Date(localDatetimeString)).getMinutes());
				} else {
					setLocalExpirationHour(9);
					setLocalExpirationMinute(0);
				}
			}
		}

	}, [product.type, product.subscriptionMaxDate]);

	// useEffect(() => {
	// 	if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.saleCollectionDate)) {
	// 		const zuluDate = product.productThematicCall.saleCollectionDate.includes('.') ? '.'+product.productThematicCall.saleCollectionDate.split('.')[1] : '';
	// 		const localTime = new Date();
	// 		const hoursOffset = localTime.getTimezoneOffset() / 60;
	// 		setLocalMaxSubscriptionDate(new Date(product.productThematicCall.saleCollectionDate.split('T')[0]+'T'+(23+hoursOffset)+':59:59'+zuluDate));
	// 	}
	// }, [product?.productThematicCall?.saleCollectionDate]);

	useEffect(() => {
		if ((product.type === 'THEMATIC_BROADCAST')) {
			if  (product.productThematicCall.saleCollectionDate) {
				// Parsing della data e ora in formato ISO 8601
				const saleCollectionDate = new Date(product.productThematicCall.saleCollectionDate);
							
				// Calcolo delle 23:59:59 locali dello stesso giorno
				saleCollectionDate.setHours(23, 59, 59, 0);

				setLocalMaxSubscriptionDate(saleCollectionDate);
			} 
		} 
	}, [product.type, product?.productThematicCall?.saleCollectionDate]);

	// useEffect(() => {
	// 	if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.expirationDate)) {
	// 		const zuluDate = product.productThematicCall.expirationDate.includes('.') ? '.'+product.productThematicCall.expirationDate.split('.')[1] : '';
	// 		const localTime = new Date();
	// 		const hoursOffset = localTime.getTimezoneOffset() / 60;
	// 		setLocalExpirationDate(new Date(product.productThematicCall.expirationDate.split('T')[0]+'T'+(23+hoursOffset)+':59:59'+zuluDate));
	// 	} 
	// }, [product?.productThematicCall?.expirationDate]);

	useEffect(() => {
		// if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.expirationDate)) {
		// 	// Parsing della data e ora in formato ISO 8601
		// 	const expirationDate = new Date(product.productThematicCall.expirationDate);
			
		// 	// Calcolo delle 23:59:59 locali dello stesso giorno
		// 	expirationDate.setHours(23, 59, 59, 0);
			
		// 	setLocalExpirationDate(expirationDate);
		// } 

		if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type !== 'ESPERIENZA') && (product.productThematicCall.expirationDate)) {
			const fixedDate = ((typeof product.productThematicCall.expirationDate === "string") && (product.productThematicCall.expirationDate.slice(-1) === 'Z')) ? product.productThematicCall.expirationDate : product.productThematicCall.expirationDate+'Z';
			const utcDate = new Date(fixedDate);
			const year = utcDate.getFullYear();
			const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
			const day = utcDate.getDate().toString().padStart(2, '0');
			const isoDate = `${year}-${month}-${day}`;
			const onlyDate = new Date(isoDate);

			if (!isValidDate(utcDate)) return;
    		const localDatetimeString = convertDate(utcDate.toLocaleString());
			setLocalExpirationDate(new Date(onlyDate));
			setLocalAbsoluteExpirationDate(isoDate);
			setLocalExpirationHour((new Date(localDatetimeString)).getHours());
			setLocalExpirationMinute((new Date(localDatetimeString)).getMinutes());
		} 
	}, [product.type, product?.productThematicCall?.expirationDate]);
	

	useEffect(() => {
		if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.liveDate)) {
			const fixedDate = ((typeof product.productThematicCall.liveDate === "string") && (product.productThematicCall.liveDate.slice(-1) === 'Z')) ? product.productThematicCall.liveDate : product.productThematicCall.liveDate+'Z';
			const utcDate = new Date(fixedDate);
			const year = utcDate.getFullYear();
			const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
			const day = utcDate.getDate().toString().padStart(2, '0');
			const isoDate = `${year}-${month}-${day}`;
			const onlyDate = new Date(isoDate);

			if (!isValidDate(utcDate)) return;
    		const localDatetimeString = convertDate(utcDate.toLocaleString());
			setLocalLiveDate(new Date(onlyDate));
			setLocalAbsoluteLiveDate(isoDate);
			setLocalLiveHour((new Date(localDatetimeString)).getHours());
			setLocalLiveMinute((new Date(localDatetimeString)).getMinutes());
		} else {
			if ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA')) {
				setLocalLiveHour(9);
				setLocalLiveMinute(0);
			}
		}
	}, [product.type,product?.productThematicCall?.liveDate]);

	useEffect(() => {

		// const productMaxDate = new Date(product.subscriptionMaxDate).getTime();
		// const productSaleCollectionDate = (product.type === 'THEMATIC_BROADCAST') && product?.productThematicCall?.saleCollectionDate ? new Date(product?.productThematicCall.saleCollectionDate).getTime() : null;
		// var productExpirationDate = (product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type !== 'ESPERIENZA') && product?.productThematicCall?.expirationDate ? new Date(product?.productThematicCall.expirationDate).getTime() : null;
		// productExpirationDate = (product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA') && product?.subscriptionMaxDate ? new Date(product?.subscriptionMaxDate).getTime() : productExpirationDate;
		// const fixedProductDate = (product.type === 'THEMATIC_BROADCAST') && (product?.productThematicCall) && (product?.productThematicCall?.liveDate) && (typeof product.productThematicCall?.liveDate === "string") && (product.productThematicCall?.liveDate.slice(-1) === 'Z') ? product.productThematicCall.liveDate : product.productThematicCall?.liveDate+'Z';
		// const fixedExpirationDate = (product.type === 'THEMATIC_BROADCAST') && (product?.productThematicCall?.type === 'ESPERIENZA') && (product?.productThematicCall) && (product?.subscriptionMaxDate) && (typeof product?.subscriptionMaxDate === "string") && (product?.subscriptionMaxDate.slice(-1) === 'Z') ? product.subscriptionMaxDate : product.subscriptionMaxDate+'Z';
		// var productLiveDate : Date | null = null;
		// var productHour = -1;
		// var productMinute = -1;
		// var prodExpirationDate : Date | null = null;
		// var prodExpirationHour = -1;
		// var prodExpirationMinute = -1;
		// if ((product.type === 'THEMATIC_BROADCAST') && fixedProductDate) {
		// 	productLiveDate = new Date(fixedProductDate);
		// 	const year = productLiveDate.getFullYear();
		// 	const month = (productLiveDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
		// 	const day = productLiveDate.getDate().toString().padStart(2, '0');
		// 	const isoDate = `${year}-${month}-${day}`;
		// 	if (!isNaN(year) && (month !== 'NaN') && (day !== 'NaN')) {
		// 		productLiveDate = new Date(isoDate);
		// 	} else {
		// 		productLiveDate = null;
		// 	}
		
		// 	const utcDate = new Date(fixedProductDate);
		// 	if (isValidDate(utcDate) && fixedProductDate!=='nullZ') {
		// 		const localDatetimeString = convertDate(utcDate.toLocaleString());
		// 		productHour = (new Date(localDatetimeString)).getHours();
		// 		productMinute = (new Date(localDatetimeString)).getMinutes();
		// 	}
		// 	if (product?.productThematicCall?.type === 'ESPERIENZA' && fixedExpirationDate) {
		// 		prodExpirationDate = new Date(fixedExpirationDate);
		// 		const year = prodExpirationDate.getFullYear();
		// 		const month = (prodExpirationDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
		// 		const day = prodExpirationDate.getDate().toString().padStart(2, '0');
		// 		const isoDate = `${year}-${month}-${day}`;
		// 		if (!isNaN(year) && (month !== 'NaN') && (day !== 'NaN')) {
		// 			prodExpirationDate = new Date(isoDate);
		// 		} else {
		// 			prodExpirationDate = null;
		// 		}
		
		// 		const utcExpDate = new Date(fixedExpirationDate);
		// 		if (isValidDate(utcExpDate) && fixedExpirationDate!=='nullZ') {
		// 			const localDatetimeString = convertDate(utcExpDate.toLocaleString());
		// 			prodExpirationHour = (new Date(localDatetimeString)).getHours();
		// 			prodExpirationMinute = (new Date(localDatetimeString)).getMinutes();
		// 		}
		// 	}
		// }
		// var maxSubscriptionDate : Date | null = null;
		
		// const fixedAbsoluteLocalDate = (product.type === 'THEMATIC_BROADCAST') && localAbsoluteLiveDate ? new Date(localAbsoluteLiveDate) : null;
		// const fixedAbsoluteExpirationDate = (product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA') && localAbsoluteExpirationDate ? new Date(localAbsoluteExpirationDate) : null;

		// var liveDateComplete = false;
		// if ((fixedAbsoluteLocalDate !== undefined) && (fixedAbsoluteLocalDate !== null) && (fixedAbsoluteLocalDate?.getFullYear() >= (new Date()).getFullYear())) liveDateComplete = true;

		// var expirationDateComplete = false;
		// if ((prodExpirationDate !== undefined) && (prodExpirationDate !== null) && (prodExpirationDate?.getFullYear() >= (new Date()).getFullYear())) expirationDateComplete = true;

		if (product.type === 'THEMATIC_BROADCAST') setCanEditLive(canEditLive || (!checkThematicBroadcastForScheduled() && !checkThematicBroadcastForPublished()));
		
		// var mod : boolean =  (localMaxSubscriptionDate && !isValidDate(localMaxSubscriptionDate)) || 
		// 			((product.type !== 'THEMATIC_BROADCAST') && localMaxSubscriptionNumber !== product.maxSubscription) || 
		// 			((product.type !== 'THEMATIC_BROADCAST') && (localMaxSubscriptionDate !== undefined) && localMaxSubscriptionDate?.getTime() !== productMaxDate) ||
		// 			// ((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type !== 'ESPERIENZA') && (((localMaxSubscriptionDate !== undefined) && (localMaxSubscriptionDate?.getTime() !== productSaleCollectionDate)) || ((localMaxSubscriptionDate === undefined) && (productSaleCollectionDate !== null)))) ||
		// 			// ((product.type === 'THEMATIC_BROADCAST') && (((localMaxSubscriptionDate !== undefined) && (localMaxSubscriptionDate?.getTime() !== productSaleCollectionDate)) || ((localMaxSubscriptionDate === undefined) && (productSaleCollectionDate !== null)))) ||
		// 			((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type !== 'ESPERIENZA') && (((localExpirationDate !== undefined) && (localExpirationDate?.getTime() !== productExpirationDate)) || ((localExpirationDate === undefined) && (productExpirationDate !== null)))) ||
		// 			(((product.type === 'THEMATIC_BROADCAST') && liveDateComplete && (fixedAbsoluteLocalDate !== null) && (productLiveDate !== null) && (fixedAbsoluteLocalDate?.getTime() !== productLiveDate?.getTime())) || (fixedAbsoluteLocalDate === null && (productLiveDate !== null)) || (liveDateComplete && productLiveDate === null && fixedAbsoluteLocalDate !== null)) || 
		// 			(((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA') && expirationDateComplete && (fixedAbsoluteExpirationDate !== null) && (prodExpirationDate !== null) && (fixedAbsoluteExpirationDate?.getTime() !== prodExpirationDate?.getTime())) || (fixedAbsoluteExpirationDate === null && (prodExpirationDate !== null)) || (expirationDateComplete && prodExpirationDate === null && fixedAbsoluteExpirationDate !== null)) || 
		// 			(((product.type === 'THEMATIC_BROADCAST') && (localLiveHour !== productHour))) ||
		// 			(((product.type === 'THEMATIC_BROADCAST') && (localLiveMinute !== productMinute))) ||
		// 			(((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA') && (localExpirationHour !== prodExpirationHour))) ||
		// 			(((product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type === 'ESPERIENZA') && (localExpirationMinute !== prodExpirationMinute))) ||
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxProtagonist === true) && (product.productThematicCall.maxProtagonist !== null) && (localMaxProtagonistNumber !== 0) && (localMaxProtagonistNumber !== product.productThematicCall.maxProtagonist)) || 
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxProtagonist === true) && (product.productThematicCall.maxProtagonist === null) && (localMaxProtagonistNumber > 0)) || 
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxProtagonist === true) && (product.productThematicCall.maxProtagonist !== null) && (localMaxProtagonistNumber === 0)) ||
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxProtagonist === false) && (product.productThematicCall.maxProtagonist !== 0)) ||
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxSpectator === true) && (product.productThematicCall.maxSpectator !== null) && (localMaxSpectatorNumber !== 0) && (localMaxSpectatorNumber !== product.productThematicCall.maxSpectator)) || 
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxSpectator === true) && (product.productThematicCall.maxSpectator === null) && (localMaxSpectatorNumber > 0)) ||
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxSpectator === true) && (product.productThematicCall.maxSpectator !== null) && (localMaxSpectatorNumber === 0)) ||
		// 			((product.type === 'THEMATIC_BROADCAST') && (canEditMaxSpectator === false) && (product.productThematicCall.maxSpectator !== 0));
					
		// console.log("MOD: ", mod);
		// const mod = true;
		// setIsModified(mod);
	}, [
		localMaxSubscriptionDate, 
		localMaxSubscriptionNumber, 
		product.maxSubscription, 
		product.subscriptionMaxDate,
		product?.productThematicCall?.saleCollectionDate,
		product?.productThematicCall?.expirationDate,
		product?.productThematicCall?.liveDate,
		product?.productThematicCall?.maxProtagonist,
		product?.productThematicCall?.maxSpectator,
		product?.productThematicCall?.type,
		product?.productThematicCall?.status,
		product.type,
		localExpirationDate,
		localLiveDate,
		localLiveHour,
		localLiveMinute,
		localMaxProtagonistNumber,
		localMaxSpectatorNumber,
		canEditMaxProtagonist,
		canEditMaxSpectator
		]);

	useEffect(() => {
		if (!brand) return;
		if (!product) return;
		const gre = async () => {
			const response = await getRoadmapEvents(brand);
			// console.log("2ROADMAP: looking for /"+product.id.toString());
			const targetEvent = response.find((e: any) => {
				// console.log("Checking event: ", e);
				if (e.link && e.link.includes(product?.productThematicCall?.type === 'ESPERIENZA' ? 'esperienza' : 'product-diretta-tematica') && e.link.endsWith("/"+product?.id.toString())) {
					return e;
				}
			})
			// console.log("2ROADMAP: target event: ", targetEvent);
			if (targetEvent) {
				// console.log("2ROADMAP: found event: ", targetEvent.id);
				setOriginalRoadmapEvent(targetEvent);
			} else { 
				//console.log("2ROADMAP: not found event"); 
			}
			
		}
		const gret = async () => {
			const response = await getRoadmapEventTypes(brand);
			if (response) { setOriginalRoadmapTypesList(response); }
		}
		gre();
		gret();
	}, [brand, product]);

	return (
		<SubscriptionLimitBox>
			{ product.type !== 'THEMATIC_BROADCAST' && <Row gap='1rem'>
				<SubscriptionLabel>Posti Massimi</SubscriptionLabel>
				<SubscriptionMaxNumber
					type='number'
					value={localMaxSubscriptionNumber > 0 ? localMaxSubscriptionNumber : ''}
					onChange={onMaxSubscriptionNumberChangeHandler}
				/>
				<RemoveButton onClick={handleRemoveSubscriptionBtn} disabled={!localMaxSubscriptionNumber} title="Rimuovi limite POSTI">&#10006;</RemoveButton>
				<SubscriptionCurrentNumber>Attuali: {product.subscriptions.toLocaleString()}</SubscriptionCurrentNumber>
			</Row>
			}

			{ product.type === 'THEMATIC_BROADCAST' &&
				<Row gap='1rem'>
				<SubscriptionLabel>{(product.productThematicCall.type === 'ESPERIENZA') ? 'Iscritti' : 'Protagonisti'} Massimi</SubscriptionLabel>
				<SubscriptionMaxNumber
					type='number'
					value={canEditMaxProtagonist ? localMaxProtagonistNumber : ''}
					onChange={onMaxProtagonistNumberChangeHandler}
					disabled={!canEditMaxProtagonist || isReadOnly}
					min={0}
				/>
				<RemoveButton onClick={handleRemoveProtagonistBtn} disabled={!localMaxProtagonistNumber || isReadOnly} title="Rimuovi limite POSTI">&#10006;</RemoveButton>
				<HoursDiv><input
					type="checkbox"
					checked={canEditMaxProtagonist}
					onChange={handleCheckboxProtagonistChange}
					disabled={(product.productThematicCall.type === 'ESPERIENZA') || isReadOnly}
				/> {(product.productThematicCall.type === 'ESPERIENZA') ? '' : 'Attiva Protagonisti'} <small>(0 = illimitati)</small>
				</HoursDiv>
				{<SubscriptionCurrentNumber>Attuali: {product.subscriptionsAsProtagonist ? product.subscriptionsAsProtagonist.toLocaleString() : 0}</SubscriptionCurrentNumber>}
			</Row>
			}

			{ (product.type === 'THEMATIC_BROADCAST') && (product.productThematicCall.type !== 'ESPERIENZA') &&
				<Row gap='1rem'>
				<SubscriptionLabel>Spettatori Massimi</SubscriptionLabel>
				<SubscriptionMaxNumber
					type='number'
					value={canEditMaxSpectator ? localMaxSpectatorNumber : ''}
					onChange={onMaxSpectatorNumberChangeHandler}
					disabled={!canEditMaxSpectator || isReadOnly}
					min={0}
				/>
				<RemoveButton onClick={handleRemoveSpectatorBtn} disabled={!localMaxSpectatorNumber || isReadOnly} title="Rimuovi limite POSTI">&#10006;</RemoveButton>
				<HoursDiv><input
					type="checkbox"
					checked={canEditMaxSpectator}
					onChange={handleCheckboxSpectatorChange}
					disabled={isReadOnly}
				/> Attiva Spettatori <small>(0 = illimitati)</small>
				</HoursDiv>
				{<SubscriptionCurrentNumber>Attuali: {product.subscriptionsAsSpectator ? product.subscriptionsAsSpectator.toLocaleString() : 0}</SubscriptionCurrentNumber>}
			</Row>
			}

			{ product.type === 'THEMATIC_BROADCAST' &&
			<Row gap='1rem'>
				<SubscriptionLabel>Data e Ora {(product.productThematicCall.type === 'ESPERIENZA') ? 'Inizio' : 'Aula'} {canEditLive === true}</SubscriptionLabel>
				<SubscriptionMaxDate
					type='date'
					// value={(localLiveDate && isValidDate(localLiveDate)) ? localLiveDate?.toISOString().substring(0, 10) : ''}
					value={localAbsoluteLiveDate}
					onChange={onLiveDateChangeHandler}
					disabled={!canEditLive || isReadOnly}
				/>
				<Col><Row>
				<select id="hourSelect" onChange={onLiveHourChangeHandler} disabled={!canEditLive || isReadOnly}>
					<option key={-1} value={-1}>--</option>
					{Array.from({ length: 24 }, (_, index) => index).map((hour) => (
					<option 
						key={hour} 
						value={hour}
						selected={(((localLiveDate && isValidDate(localLiveDate)) || product?.productThematicCall?.type === 'ESPERIENZA') && (localLiveHour === hour)) ? true : false }
					>
						{hour < 10 ? `0${hour}` : hour}
					</option>
					))}
				</select>
				<HoursDiv>:</HoursDiv>
				<select id="minuteSelect" onChange={onLiveMinuteChangeHandler} disabled={!canEditLive || isReadOnly}>
					<option key={-1} value={-1}>--</option>
					{Array.from({ length: 4 }, (_, index) => index * 15).map((minute) => (
					<option 
						key={minute} 
						value={minute}
						selected={(((localLiveDate && isValidDate(localLiveDate)) || product?.productThematicCall?.type === 'ESPERIENZA') && (localLiveMinute === minute)) ? true : false }
					>
						{minute < 10 ? `0${minute}` : minute}
					</option>
					))}
				</select>
				<HoursDiv><small>Tuo orario locale(!)</small></HoursDiv>
				</Row></Col>
				{ canEditLive && <RemoveButton onClick={handleRemoveLiveDateBtn} disabled={!localLiveDate || isReadOnly} title={"Azzera DATA "+((product.productThematicCall.type === 'ESPERIENZA') ? "ESPERIENZA" : "DIRETTA")}>&#10006;</RemoveButton> }
				{ !canEditLive && !checkThematicBroadcastForPublished() && <UnlockButton onClick={handleUnlockLiveDataBtn} disabled={!localLiveDate || isReadOnly} title={"Sblocca DATA "+((product.productThematicCall.type === 'ESPERIENZA') ? "ESPERIENZA" : "DIRETTA")}>&#128275;</UnlockButton> }
			</Row>
			}

			{ product.type !== 'THEMATIC_BROADCAST' &&
			<Row gap='1rem'>
				<SubscriptionLabel>Data Massima</SubscriptionLabel>
				<SubscriptionMaxDate
					type='date'
					value={(localMaxSubscriptionDate && isValidDate(localMaxSubscriptionDate)) ? localMaxSubscriptionDate?.toISOString().substring(0, 10) : ''}
					onChange={onMaxSubscriptionDateChangeHandler}
				/>
				<RemoveButton onClick={handleRemoveMaxDateBtn} disabled={!localMaxSubscriptionDate} title="Rimuovi limite DATA acquisto">&#10006;</RemoveButton>
			</Row>
			}

			{ product.type === 'THEMATIC_BROADCAST' &&
			<Row gap='1rem' style={{ display: (product.productThematicCall.type === 'ESPERIENZA') ? 'visible' : 'none' }}>
				<SubscriptionLabel>Data {(product.productThematicCall.type === 'ESPERIENZA') ? 'Fine Esperienza' : 'Obsoleto'}</SubscriptionLabel>
				<SubscriptionMaxDate
					type='date'
					value={(localMaxSubscriptionDate && isValidDate(localMaxSubscriptionDate)) ? localMaxSubscriptionDate?.toISOString().substring(0, 10) : ''}
					onChange={onMaxSubscriptionDateChangeHandler}
					disabled={isReadOnly}
				/>
				<RemoveButton onClick={handleRemoveMaxDateBtn} disabled={!localMaxSubscriptionDate || isReadOnly} title={"Rimuovi "+(product.productThematicCall.type === 'ESPERIENZA') ? "" : "limite" + " DATA " + (product.productThematicCall.type === 'ESPERIENZA') ? "fine esperienza" : "obsoleto"}>&#10006;</RemoveButton>
			</Row>
			}

			{ product.type === 'THEMATIC_BROADCAST' &&
			<Row gap='1rem' style={{ display: (product.productThematicCall.type === 'ESPERIENZA') ? 'visible' : 'none' }}>
				<SubscriptionLabel>Data {(product.productThematicCall.type === 'ESPERIENZA') ? "Chiusura Vendite" : "Scadenza"}</SubscriptionLabel>
				<SubscriptionMaxDate
					type='date'
					value={(localExpirationDate && isValidDate(localExpirationDate)) ? localExpirationDate?.toISOString().substring(0, 10) : ''}
					onChange={onExpirationDateChangeHandler}
					disabled={isReadOnly}
				/>

				<Col><Row>
				<select id="hourExpirationSelect" onChange={onHourExpirationChangeHandler} disabled={isReadOnly}>
					<option key={-1} value={-1}>--</option>
					{Array.from({ length: 24 }, (_, index) => index).map((hourExpiration) => (
					<option 
						key={hourExpiration} 
						value={hourExpiration}
						selected={(((localExpirationDate && isValidDate(localExpirationDate)) || product?.productThematicCall?.type === 'ESPERIENZA') && (localExpirationHour === hourExpiration)) ? true : false }
					>
						{hourExpiration < 10 ? `0${hourExpiration}` : hourExpiration}
					</option>
					))}
				</select>
				<HoursDiv>:</HoursDiv>
				<select id="minuteExpirationSelect" onChange={onMinuteExpirationChangeHandler} disabled={isReadOnly}>
					<option key={-1} value={-1}>--</option>
					{Array.from({ length: 4 }, (_, index) => index * 15).map((minuteExpiration) => (
					<option 
						key={minuteExpiration} 
						value={minuteExpiration}
						selected={(((localExpirationDate && isValidDate(localExpirationDate)) || product?.productThematicCall?.type === 'ESPERIENZA') && (localExpirationMinute === minuteExpiration)) ? true : false }
					>
						{minuteExpiration < 10 ? `0${minuteExpiration}` : minuteExpiration}
					</option>
					))}
				</select>
				<HoursDiv><small>Tuo orario locale(!)</small></HoursDiv>
				</Row></Col>

				<RemoveButton onClick={handleRemoveExpirationDateBtn} disabled={!localExpirationDate || isReadOnly} title={"Rimuovi DATA "+((product.productThematicCall.type === 'ESPERIENZA') ? "Chiusura Vendite" : "Scadenza")}>&#10006;</RemoveButton>
			</Row>
			}

			<SaveButton
				disabled={!isModified || isReadOnly}
				onClick={saveHandler}
			>
				SALVA MODIFICHE
			</SaveButton>
			{checkThematicBroadcastForCreated() &&
				<SaveButton
				disabled={ isModified || isPublishing || (localAbsoluteLiveDate === '') || !isValidDate(localAbsoluteLiveDate) || (localLiveHour < 0) || (localLiveMinute < 0) || isReadOnly}
				onClick={publishHandler}
			> 
				PUBBLICA {(product.productThematicCall.type !== 'ESPERIENZA') ? "AULA" : ""}
			</SaveButton>
			}
			{(checkThematicBroadcastForScheduled() || checkThematicBroadcastForPublished()) && (product.productThematicCall.type !== 'ESPERIENZA') && (Object.keys(originalRoadmapEvent).length === 0) &&
			<SaveButton
				disabled={ isModified || isGoingToRoadmap || (localAbsoluteLiveDate === '') || !isValidDate(localAbsoluteLiveDate) || (localLiveHour < 0) || (localLiveMinute < 0) || isReadOnly}
				onClick={goToRoadmapHandler}
			> 
				MANDA A ROADMAP
			</SaveButton>
			}

		</SubscriptionLimitBox>
	);
};

export default SubscriptionLimit;
