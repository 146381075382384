import { FC, useEffect, useState } from 'react';

//types
import { CategoryProps } from './Category.component.d';
import { Product } from 'types/Product';

//style
import { CategoryBox } from './style/Category.component.style';

const Category: FC<CategoryProps> = ({ category, product, putProduct, isReadOnly }) => {
	const [isEnabled, setIsEnabled] = useState(false);

	const clickHandler = async () => {
		const updatedProduct: Partial<Product> = { id: product.id };
		if (!isEnabled) {
			try {
				updatedProduct.categories = [...product.categories, category];
				await putProduct(updatedProduct);
			} catch (error) {
				console.log(error);
			}
		}
		if (isEnabled) {
			try {
				updatedProduct.categories = product.categories.filter((c) => c.id !== category.id);
				if (product.subCategories) updatedProduct.subCategories = product.subCategories.filter((s) => s.categoryId !== category.id);
				await putProduct(updatedProduct);
			} catch (error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		setIsEnabled(product.categories?.some((pc) => pc.id === category.id));
	}, [category, product]);

	return (
		<CategoryBox
			onClick={clickHandler}
			enabled={isEnabled}
			disabled={isReadOnly}
		>
			{category?.name}
		</CategoryBox>
	);
};

export default Category;
