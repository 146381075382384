import React, { FC, useEffect, useState } from 'react';

// hooks
import useHasRole from 'hooks/useHasRole';

//types
import { EditableTextAreaProps } from './EditableTextArea.component.d';

//assets
import { ReactComponent as EditIcon } from './assets/editIcon.svg';

//style
import { 
	EditableTextAreaBox,
	TitleModify,
	TextBlock,
	TextBox,
	EditBox
 } from './style/EditableTextArea.component.style';
import SectionEditorModal from 'components/SectionEditorModal/SectionEditorModal.component';

const EditableTextArea: FC<EditableTextAreaProps> = ({
	productId,
	blockId,
	typeOfLayout,
	textAreaId,
	editableTextArea,
	isRequired,
	numberOfElements,
	typeOfTextToUpload,
	update,
	refreshProduct,
	disabled
}) => {
	const [isEditorEnabled, setIsEditorEnabled] = useState(false);
	const [isOnHover, setIsOnHover] = useState(false);
	const [localContent, setLocalContent] = useState('');
	const [oldContent, setOldContent] = useState('');

    const isAdmin = useHasRole('ADMIN');
    const isSuperAdmin = useHasRole('SUPERADMIN');
	var isDiretta = useHasRole('DIRETTA');
	const isRegistaLDL = useHasRole('REGISTA-LDL');
	const isRegistaMB = useHasRole('REGISTA-MB')
	const isRegistaTCR = useHasRole('REGISTA-TCR');
	const isRegistaOMK = useHasRole('REGISTA-OMK');
	const isEditorLDL = useHasRole('EDITOR-LDL');
	const isEditorMB = useHasRole('EDITOR-MB');
	const isEditorTCR = useHasRole('EDITOR-TCR');
	const isEditorOMK = useHasRole('EDITOR-OMK');
	isDiretta = isDiretta || isRegistaLDL || isRegistaMB || isRegistaTCR || isRegistaOMK || isEditorLDL || isEditorMB || isEditorTCR || isEditorOMK;
	
    const userHasAdminRole = isAdmin || isSuperAdmin || isDiretta;

	const onMouseOverHandler = () => setIsOnHover(true);
	const onMouseOutHandler = () => setIsOnHover(false);
	const onCloseModalHandler = () => setIsEditorEnabled(false);
	
	const onModifyImageClickHandler = () => {
		        
        if (!userHasAdminRole) return;
		setIsEditorEnabled(prevState => !prevState);
	};

	useEffect(() => {
		setLocalContent(editableTextArea);
	}, [editableTextArea]);
	
	return (
		<>
			<TextBlock
				onMouseOver={onMouseOverHandler}
				onMouseLeave={onMouseOutHandler}
				onClick={onModifyImageClickHandler}
				isUserAdmin={userHasAdminRole}
				disabled={disabled}
			>
				<TextBox 
					dangerouslySetInnerHTML={{__html: localContent}} 
				/>

				{isOnHover && userHasAdminRole && !disabled && (
					<EditBox>
						Modifica testo
					</EditBox>
				)}
			</TextBlock>

			{isEditorEnabled && userHasAdminRole && !disabled && (
				<SectionEditorModal 
					onCloseButtonModal={onCloseModalHandler}
					editableContent={editableTextArea}
					update={update}
					productId={productId}
					blockId={blockId}
					elementId={textAreaId}
					typeOfElement='TEXT'
					isRequired={isRequired}
					updateData={refreshProduct}
					typeOfLayout={typeOfLayout}
					numberOfElements={numberOfElements}
					typeOfTextToUpload={typeOfTextToUpload}
				/>
			)}
		</>
	);
};

export default EditableTextArea;
